import { useState, useRef } from "react";

function DragDropFileSingle({ image, setImage }) {
  const img_upload = require('../assets/img_upload.png')

  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {

      const objectUrl = URL.createObjectURL(e.dataTransfer.files[0])
      setImage({ file: e.dataTransfer.files[0], preview: objectUrl })
      return () => URL.revokeObjectURL(objectUrl)

    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const objectUrl = URL.createObjectURL(e.target.files[0])
      setImage({ file: e.target.files[0], preview: objectUrl })
      return () => URL.revokeObjectURL(objectUrl)

    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <div>
      <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
        <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} accept=".jpg, .jpeg, .png, .mov, .mp4, .ogg" />
        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
          <div>
            {/* <p>Drag and drop your file here or</p> */}
            <div>
              <img src={img_upload} alt="img upload" />

            </div>
            <button className="upload-button" onClick={onButtonClick}>Drag and drop a file here or choose one from your computer</button>
            <p className="text-thin p_x small">Supports: JPG, PNG, JPEG</p>
          </div>
        </label>
        {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
      </form>
    </div>

  );
};

function DragDropFile({ products, setProducts }) {
  const [files, setFiles] = useState([])
  // const [preview, setPreview] = useState([])
  const img = require('../assets/photo_card_example.jpg')
  const img_upload = require('../assets/img_upload.png')



  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);

    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {


      Array.prototype.forEach.call(e.dataTransfer.files, function (file) {
        setFiles(oldArray => [...oldArray, file]);
        const objectUrl = URL.createObjectURL(file)
        setProducts(oldArray => [...oldArray, { file: file, preview: objectUrl }])
        return () => URL.revokeObjectURL(objectUrl)
      });

    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {

      Array.prototype.forEach.call(e.target.files, function (file) {
        // console.log(file)
        setFiles(oldArray => [...oldArray, file]);
        const objectUrl = URL.createObjectURL(file)

        setProducts(oldArray => [...oldArray, { file: file, preview: objectUrl }])
        return () => URL.revokeObjectURL(objectUrl)

      });

    }
  };


  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <div>
      <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
        <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} accept=".jpg, .jpeg, .png, .mov, .mp4, .ogg" />
        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
          <div>
            <div>
              <img src={img_upload} alt="img upload" />

            </div>
            <button className="upload-button" onClick={onButtonClick}>Drag and drop a file here or choose one from your computer</button>
            <p className="text-thin p_x small">Supports: JPG, PNG, JPEG, MOV, MP4 and OGG</p>
          </div>
        </label>
        {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
      </form>

    </div>


  );
};

export default DragDropFile
export { DragDropFileSingle }