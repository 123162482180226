import { SessionDetails } from "../controllers/AccountController"
import Navigation from "../components/Navigation"
import { Link, useParams, useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Footer } from '../components/Footer';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo, useState, useEffect, Component, useId, Suspense, lazy } from "react";
import { google_maps_api_key, url_server } from '../settings';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import DragDropFile from "../components/DragDropFile";
import Compressor from 'compressorjs';
import axios from 'axios';
import SetDiscount from "../components/Discounts";
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import InputGroup from 'react-bootstrap/InputGroup';
import watermarkedImage from "../components/WatermarkedImage";
import { ImageCompressor, ImageResizer } from "../controllers/ImageCompressor";
import { TimePicker } from 'antd';
import * as Dayjs from 'dayjs';
import { LazyLoadImage, trackWindowScroll  } from "react-lazy-load-image-component";
import UploadFilesImagesSection from "../components/UploadFilesImagesSection";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';


// const UploadFilesImagesSection = lazy(() => import("../components/UploadFilesImagesSection"))



function LoadingModal({show, setShow, progress, max, setActiveCard, album_id}){
    // const [show, setShow] = useState(false);
    const img_tick = require('../assets/img_tick.png')
    const [accountDetails, setAccountDetails] = useState(SessionDetails())
    const img_successfull = require('../assets/img_successfull.png')
    function handleClose(){
        setShow(false);
        // window.location.href="/"+accountDetails.user_unique_id;
        window.location.href="/album/"+album_id
        
        
    }

    return (
        <>
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
            <Modal.Title>{progress===max ? "Album published" : "Uploading images and videos..."}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {progress !== max ?(
                    <div>
                        <div className="text-center mb-3">
                            <div className="progress">
                                <ProgressBar className="w-100" min={0} max={max} now={progress} variant={progress===max ? "success": ""} />;
                            </div>

                        </div>
                        <p className="text-center">Your photos and videos are uploading, please be patient</p>
                    </div>
                ) :(
                    <div>
                        <div className="text-center mb-3">
                            <img src={img_successfull} alt="succesfull" className="mb-5" />
                           <p className="p_large mb-3"><b>Album successfully published!</b></p>
                           {/* <p className="p_small text-grey">You can now see this album on your profile. If you need to edit you can also do it on your profile</p> */}

                        </div>
                    </div>
                )}
                
                
            </Modal.Body>
            <Modal.Footer>
                {progress===max ? (
                    <Button className={'btn_primary_blue btn_register w-100' + (progress<max ? 'disabled': "" )} onClick={handleClose}>Go to album</Button>

                ): (
                    ""
                )}

            </Modal.Footer>
        </Modal>
        </>
    );
}

function SuccessFulModal({show}){
    // const [show, setShow] = useState(false);
    const [userDetails, setUserDetails] = useState(SessionDetails())
    const img_tick = require('../assets/img_tick.png')

    function handleClose(){
        // setShow(false);
        window.location.href = "/"+userDetails.user_unique_id
    }
    // const handleShow = () => setShow(true);

    return (
        <>

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
            <Modal.Title>Album Published</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center mb-3">
                    <img src={img_tick} alt="success"/>

                </div>
                <p className="text-center">Album successfully published!</p>
                {/* <p className="text-center text-thin text_grey">You can now see this album on your profile. If you need to edit you can also do it on your profile</p> */}
            </Modal.Body>
            <Modal.Footer>
            <Button className='btn_primary_blue btn_register w-100'  onClick={handleClose}>Go to profile</Button>

            </Modal.Footer>
        </Modal>
        </>
    );
}


function SectionReview({userInfo, productsGlobal, setProductsGlobal, album, locations, discountsGlobal, pricesGlobal}){

    const [products, setProducts] = useState(productsGlobal.length > 0 ? productsGlobal : [])
    const ic_location = require('../assets/ic_location_card.png')
	const ic_calendar = require('../assets/ic_calendar_card.png')
    const ic_empty_profile = require('../assets/ic_empty_profile.png')
    const ic_mini_instagram = require('../assets/ic_mini_instagram.png')

    var dayNames = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
	var monthPrefixes = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

	var start_time = album.album_datetime_start != null ? new Date(album.album_datetime_start).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'}) : ""
	var end_time = album.album_datetime_end != null ? new Date(album.album_datetime_end).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'}) : ""
	var day = new Date(album.album_datetime_start).getDay()
	var month = album.album_datetime_start != null ?  new Date(album.album_datetime_start).getMonth() : ""
	var day_number = album.album_datetime_start != null ? new Date(album.album_datetime_start).toLocaleDateString().split("/")[0] : ""
	var year_number = album.album_datetime_start != null ?  new Date(album.album_datetime_start).toLocaleDateString().split("/")[2] : ""


    function changePrice(index, value){
        
        var newArr = [...products]
        newArr[index].price = parseInt(parseFloat(value) * 100)
        setProducts(newArr)
    }

    


    useEffect(() =>{

        setProductsGlobal(products)
    }, [products])


    return(
        <div>
            <div className='d-flex justify-content-between mt-5'>
                <div>
                    <h4 className='mb-2'><b>{locations.location_name}</b></h4>
                    <div className='mb-2'>
                        <img src={ic_location} alt="icon" className='pb-1' />
                        <p className='text-thin text_inline'>{day_number} {monthPrefixes[month]} {year_number} | {start_time} {start_time != "" && end_time != "" ? "-" : ""} {end_time}</p>
                    </div>
                    <div className='mb-2'>
                        <img src={ic_calendar} alt='icon' className='pb-1' />
                        <p className='text_inline'>{locations.location_city} {locations.location_city != null && locations.location_state != null ? "-" : ""} {locations.location_state}</p>

                    </div>

                </div>
                <div>
                    <div className='d-flex align-items-center' id='album_photographer_details'>
                        <img  src={ic_empty_profile} alt='empty profile' className="img_profile" />
                        <div>
                            <p className='m-0'><b>{userInfo.photographer_business_name}</b></p>

                            <div>
                                <img src={ic_mini_instagram} alt="icon" className='me-1' />
                                <a href={'https://www.instagram.com/' + userInfo.photographer_instagram} className='text-thin p_xsmall m-0'>{userInfo.photographer_instagram}</a>
                            </div>

                        
                        </div>

                    </div>
                    <p className='mt-3'>{userInfo.album_categories}</p>
                    

                </div>
            </div>

            {discountsGlobal.length > 0 ?(
                <div className="mt-3 div_discounts_review">
                    <Row className="pt-2 pb-2">
                        {discountsGlobal.map((obj, index) =>{
                            return(
                                <Col key={index} lg={discountsGlobal.length < 3 ? 12 / discountsGlobal.length : 4 } className="text-center">
                                    <p className="text-blue m-0"><b>{parseInt(obj.discount_percentage * 100)}% OFF</b><span className="text-grey"> - Buying {obj.discount_quantity}</span></p>
                                </Col>
                            )
                        })}
                       
                    </Row>
                </div>

             ) : ("")} 
            

            <div className="mt-3 mb-5"> 
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="text-blue m-0"><b>Photos</b></h5>
                </div>
                <Row className='gx-4'>

                    {products.map((obj, index) => {
                        var file_extension = products[index].file.name.split('.');
                        file_extension = file_extension[file_extension.length - 1];
                        products[index].photographer_id = userInfo.photographer_id
                        products[index].location_id = album.location_id
                        
                        

                        if(file_extension.toLowerCase() == "png" || file_extension.toLowerCase() == "jpg" || file_extension.toLowerCase() == "jpeg"){
                            products[index].product_type_id = 1

                            return (

                                <Col key={index} md={2} >
                                    <div>
                                        <Card.Img className='card_round square mt-3' variant="top" src={obj.preview} />

                                        <Form.Label className="m-0 ">Price (AUD)</Form.Label>
                                        <Form.Control data-value={index} type="number" className='form-control-md w-100 mt-1'  placeholder='Price' defaultValue={obj.price != null ? parseFloat(obj.price)/100 : parseFloat(userInfo.photographer_default_price_photo)/100} onChange={(e) => changePrice(index, e.target.value)}/>
                                        {isNaN(parseFloat(obj.price)) || isNaN(parseFloat(userInfo.photographer_default_price_photo)) ? (
                                            <></>
                                        ) : (
                                            <p className="text-thin mt-1"><b>You will get </b>${obj.price != null ? (((parseFloat(obj.price)) * userInfo.photographer_percentage_receive)/100).toFixed(2) : (((parseFloat(userInfo.photographer_default_price_photo)) * userInfo.photographer_percentage_receive)/100).toFixed(2)}</p>
                                        )}
                                    </div>
                                    
    
                                </Col>
    
                            );
                        }else if(file_extension.toLowerCase() == "mp4" || file_extension.toLowerCase() == "ogg" || file_extension.toLowerCase() == "mov"){
                            products[index].product_type_id = 2
                            return(
                                <Col key={index} md={2} >
                                    <div >
                                        <video className="card_round  mt-3" controls>
                                            <source  src={obj.preview} type="video/mp4"/>
                                            <source  src={obj.preview} type="video/ogg"/>
                                            <source  src={obj.preview} type="video/mov"/>

                                            Your browser does not support the video tag.
                                        </video>

                                        <Form.Label className="m-0 ">Price (AUD)</Form.Label>
                                        <Form.Control data-value={index} type="number" className='form-control-md w-100 mt-1'  placeholder='Price' defaultValue={obj.price != null ? parseFloat(obj.price)/100 : parseFloat(userInfo.photographer_default_price_video)/100} onChange={(e) => changePrice(index, e.target.value)}/>
                                        {isNaN(parseFloat(obj.price)) || isNaN(parseFloat(userInfo.photographer_default_price_photo)) ? (
                                            <></>
                                        ) : (
                                            <p className="text-thin mt-1"><b>You will get </b>${obj.price != null ? (((parseFloat(obj.price)) * userInfo.photographer_percentage_receive)/100).toFixed(2) : (((parseFloat(userInfo.photographer_default_price_photo)) * userInfo.photographer_percentage_receive)/100).toFixed(2)}</p>
                                        )}
                                    </div>
                                    
    
                                </Col>
                            )
                        }

                        
                    })}
                    
                </Row>

            </div>
        </div>
    )
}



function SectionSetDiscounts({
                setActiveCard, 
                userInfo, 
                pricesGlobal, 
                setPricesGlobal, 
                discountsGlobal, 
                setDiscountsGlobal, 
                productsGlobal, 
                setProductsGlobal,
                savePricesGlobal,
                setSavePricesGlobal,
                saveDiscountsGlobal,
                setSaveDiscountsGlobal}){

    const [prices, setPrices] = useState({})
    const [accountDetails, setAccountDetails] = useState(SessionDetails())
    const [showModal, setShowModal] = useState(false)
    const [album, setAlbum] = useState({})
    const [products, setProducts] = useState(productsGlobal.length > 0 ? productsGlobal : [])
    const [discounts, setDiscounts] = useState(discountsGlobal.length > 0 ? discountsGlobal : [{discount_quantity: "", discount_percentage: ""}])

                    // console.log(productsGlobal)
    useEffect(() =>{
        var obj_discounts = []
        discounts.map((obj, index) =>{
            if(obj.discount_quantity != "" || (obj.discount_percentage != "" && !isNaN(obj.discount_percentage))){
                obj_discounts.push(obj)
            }
        })

        setDiscountsGlobal(obj_discounts)
    }, [discounts])

    useEffect(() =>{
        var p = [...products]
        products.map((obj, index) =>{
            var file_extension = products[index].file.name.split('.');
            file_extension = file_extension[file_extension.length - 1];

            if(obj.price == null && (pricesGlobal == null || pricesGlobal.photographer_default_price_photo == null || pricesGlobal.photographer_default_price_photo == "") && userInfo.photographer_default_price_photo){
                if(file_extension.toLowerCase() == "png" || file_extension.toLowerCase() == "jpg" || file_extension.toLowerCase() == "jpeg"){
                    p[index].price = userInfo.photographer_default_price_photo
                }
            }else if(obj.price == null && (pricesGlobal.photographer_default_price_photo != null || pricesGlobal.photographer_default_price_photo != "")){
                if(file_extension.toLowerCase() == "png" || file_extension.toLowerCase() == "jpg" || file_extension.toLowerCase() == "jpeg"){
                    p[index].price = pricesGlobal.photographer_default_price_photo
                }
            }

            if(obj.price == null && (pricesGlobal == null || pricesGlobal.photographer_default_price_video == null || pricesGlobal.photographer_default_price_video == "") && userInfo.photographer_default_price_video){
                if(file_extension.toLowerCase() == "mp4" || file_extension.toLowerCase() == "ogg" || file_extension.toLowerCase() == "mov"){
                    p[index].price = userInfo.photographer_default_price_video

                }
            }else if(obj.price == null && (pricesGlobal.photographer_default_price_video != null || pricesGlobal.photographer_default_price_video != "")){
                if(file_extension.toLowerCase() == "mp4" || file_extension.toLowerCase() == "ogg" || file_extension.toLowerCase() == "mov"){
                    p[index].price = pricesGlobal.photographer_default_price_video

                }
            }
        })

        setProductsGlobal(products)
    }, [])

    useEffect(() =>{
        if(Object.keys(prices).length > 0){

            // console.log("entra prices modified")
            var p = [...products]
            products.map((obj, index) =>{
                var file_extension = products[index].file.name.split('.');
                file_extension = file_extension[file_extension.length - 1];
    
                if(prices.photographer_default_price_photo){
                    if(file_extension.toLowerCase() == "png" || file_extension.toLowerCase() == "jpg" || file_extension.toLowerCase() == "jpeg"){
                        p[index].price = prices.photographer_default_price_photo
                    }
                }
    
                if(prices.photographer_default_price_video){
                    if(file_extension.toLowerCase() == "mp4" || file_extension.toLowerCase() == "ogg" || file_extension.toLowerCase() == "mov"){
                        p[index].price = prices.photographer_default_price_video
    
                    }
                }
            })

            setProductsGlobal(products)
            setPricesGlobal(prices)

        }
    }, [prices])

    // useEffect(() =>{
    //     if(album_id != null){
    //         // get album from id
    //             fetch(url_server + "public/albums?album_id="+album_id, {
    //             method: 'GET',
                
    //             // TODO: get access token from local storage instead
    //             headers: {
    //                 'Content-type': 'application/json; charset=UTF-8',
    //                 // "x-access-token": accountDetails.token,
    //             },
    //             })
    //             .then((response) => response.json())
                
    //             .then( (data) => {
    //             // GO TO SUCCESS PAGE
    //                 // setAlbum(data.response[0])
    
    //             })
    //             .catch((err) => {
    //                 // console.log(err)
    //                 // alert("Something went wrong. Please check your connection and try again.");
    //             });

    //     }
    // }, [])


    function addDiscount(){
        setDiscounts(oldArray => [...oldArray, {discount_quantity: "", discount_percentage: ""}])

    }

    async function onNext(){
        // var finish = true;
        // validatePrices()
        // if(Object.keys(prices).length > 0){
        //     // save prices into database
        //     await sendPrices()
        // }

        // finish = validateDiscounts()
        // if(discounts.length > 0){
        //     await sendDiscounts()
        // }

        // if(finish){
        //     setShowModal(true)

        // }

        
    }

    function onBack(){
        // setActiveCard({activeIndex: 2})
        // window.location.href = "?step=1&album_id="+album_id
    }


    function validatePrices(){
        if(isNaN(prices.photographer_default_price_photo)){
            delete prices.photographer_default_price_photo
        }

        if(isNaN(prices.photographer_default_price_video)){
            delete prices.photographer_default_price_video
        }

    }

    function validateDiscounts(){
        if(discounts.length > 0){
            
            for(var i = 0; i < discounts.length; i++){
                if(discounts[i].discount_quantity == "" || discounts[i].discount_percentage == ""){
                    alert("Please set all discounts")
                    return false;
                }
            }
        }
        return true;
    }

    async function sendPrices(){

        fetch(url_server + "private/photographers/"+userInfo.photographer_id, {
            method: 'PUT',
            body: JSON.stringify(prices),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token,
            },
            })
            .then((response) => response.json())
            
            .then(async (data) => {
                // response = data.response
            
            })
            .catch((err) => {
                // console.log(err)
            });
        
    }

    async function sendDiscounts(){
        fetch(url_server + "private/albums-discounts", {
            method: 'POST',
            body: JSON.stringify(discounts),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token,
            },
            })
            .then((response) => response.json())
            
            .then(async (data) => {
                // response = data.response
            
            })
            .catch((err) => {
                // console.log(err)
            });
    }


    const getDefaultPrice = (price_type) => {
        const parseAndCheck = (value) => {
            const parsedValue = Number(value);
            return Number.isFinite(parsedValue) ? parsedValue : 0;
          };
        
        
        if(price_type === 1){
            let price = 0;

            if (prices.photographer_default_price_photo != null) {
                price = parseAndCheck(prices.photographer_default_price_photo);
            } else if (pricesGlobal.photographer_default_price_photo != null) {
                price = parseAndCheck(pricesGlobal.photographer_default_price_photo);
            } else if (userInfo.photographer_default_price_photo != null && userInfo.photographer_default_price_photo != "") {
                price = parseAndCheck(userInfo.photographer_default_price_photo);
            }
            
            return (price * userInfo.photographer_percentage_receive) / 100;
        }else{
            let price = 0;

            if (prices.photographer_default_price_video != null) {
                price = parseAndCheck(prices.photographer_default_price_video);
            } else if (pricesGlobal.photographer_default_price_video != null) {
                price = parseAndCheck(pricesGlobal.photographer_default_price_video);
            } else if (userInfo.photographer_default_price_video != null && userInfo.photographer_default_price_video != "") {
                price = parseAndCheck(userInfo.photographer_default_price_video);
            }
            
              return (price * userInfo.photographer_percentage_receive) / 100;
        }
        
       
    };
      
    const shouldDisplayPrice = (price_type) => {

        if(price_type === 1){
            const hasChangedOnce = pricesGlobal.photographer_default_price_photo !== null;
            const pricesValue = prices.photographer_default_price_photo;
    
            return (
                (hasChangedOnce && pricesValue !== null && pricesValue !== "" && !isNaN(Number(pricesValue))) ||
                (userInfo.photographer_default_price_photo !== null &&
                userInfo.photographer_default_price_photo !== "" &&
                !isNaN(Number(userInfo.photographer_default_price_photo)))
            );
        }else{
            const hasChangedOnce = pricesGlobal.photographer_default_price_video !== null;
            const pricesValue = prices.photographer_default_price_video;

            return (
                (hasChangedOnce && pricesValue !== null && pricesValue !== "" && !isNaN(Number(pricesValue))) ||
                (userInfo.photographer_default_price_video !== null &&
                userInfo.photographer_default_price_video !== "" &&
                !isNaN(Number(userInfo.photographer_default_price_video)))
            );
        }
        
    };
    
    return(
        <div>
            <Row>
                <Col lg={6} className="mt-5 mb-5">
                    <h5 className="text-blue"><b>Prices</b></h5>
                    <p className="text-thin text_grey">You can set up default prices for future photos and videos.</p>
                    <Form.Group className="mb-3" controlId="formBasicBrand">
                        <Form.Label className="fw-bold ">Price per photo (AUD)</Form.Label>
                        <Form.Control  required className="pt-3 pb-3" type="number" placeholder="Type here" defaultValue={pricesGlobal.photographer_default_price_photo != null ? (parseFloat(pricesGlobal.photographer_default_price_photo) / 100).toFixed(2) : (parseFloat(userInfo.photographer_default_price_photo) / 100).toFixed(2)} onChange={(e) => setPrices((oldSearch) => ({...oldSearch, "photographer_default_price_photo": parseInt(parseFloat(e.target.value)*100)}))} />
                        <div id="emailHelp" className="form-text">How much the surfer will pay for the photo (price before service fee deduction).</div>
                        {shouldDisplayPrice(1) ? (
                            <p className="text-thin mt-1 form-text text-blue">
                            <b>You will get </b>${getDefaultPrice(1).toFixed(2)}
                            </p>
                        ) : (
                            <></>
                        )}
                                                
                        {/* {(userInfo.photographer_default_price_photo != "" && userInfo.photographer_default_price_photo != null) || (pricesGlobal.photographer_default_price_photo != null) || (!isNaN(prices.photographer_default_price_photo))  ? (
                            // <p className="text-thin mt-1 form-text text-blue"><b>You will get </b>${pricesGlobal.photographer_default_price_photo != null ? (((parseFloat(pricesGlobal.photographer_default_price_photo)) * userInfo.photographer_percentage_receive)/100).toFixed(2) : (((parseFloat(userInfo.photographer_default_price_photo)) * userInfo.photographer_percentage_receive)/100).toFixed(2)}</p>
                            
                            <p className="text-thin mt-1 form-text text-blue"><b>You will get </b>${
                                prices.photographer_default_price_photo != null && !isNaN(prices.photographer_default_price_photo)
                                ? 

                                    (((parseFloat(prices.photographer_default_price_photo)) * userInfo.photographer_percentage_receive)/100).toFixed(2) 
                                : 
                                    ( 
                                    pricesGlobal.photographer_default_price_photo != null && pricesGlobal.photographer_default_price_photo != "" 
                                    ? 
                                        (((parseFloat(pricesGlobal.photographer_default_price_photo)) * userInfo.photographer_percentage_receive)/100).toFixed(2)
                                    : 
                                        userInfo.photographer_default_price_photo != null && userInfo.photographer_default_price_photo != "" 
                                        ?
                                        (((parseFloat(userInfo.photographer_default_price_photo)) * userInfo.photographer_percentage_receive)/100).toFixed(2)
                                        : 
                                        "0.00"
                                    
                                    )
                                }</p>

                        ) : (
                            <></>
                        )} */}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicServices">
                        <div className='radio_inline_blue'>
                            <Form.Check
                            
                                inline
                                label="Save as your new standard prices"
                                name="group1"
                                type="checkbox"
                                // value={true}
                                defaultChecked={savePricesGlobal != null ? savePricesGlobal.save_photos : false}
                                onChange={(e) => setSavePricesGlobal((oldSearch) => ({...oldSearch, save_photos: e.target.checked}))}
                            />
                       
                    
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicBrand">
                        <Form.Label className="fw-bold ">Price per video (AUD)</Form.Label>
                        <Form.Control  required className="pt-3 pb-3" type="number" placeholder="Type here" defaultValue={pricesGlobal.photographer_default_price_video != null ? (parseFloat(pricesGlobal.photographer_default_price_video) / 100).toFixed(2) : (parseFloat(userInfo.photographer_default_price_video) / 100).toFixed(2)} onChange={(e) => setPrices((oldSearch) => ({...oldSearch, "photographer_default_price_video": parseInt(parseFloat(e.target.value)*100)}))} />
                        <div id="emailHelp" className="form-text">How much the surfer will pay for the video (price before service fee deduction).</div>
                        {shouldDisplayPrice(2) ? (
                            <p className="text-thin mt-1 form-text text-blue">
                            <b>You will get </b>${getDefaultPrice(2).toFixed(2)}
                            </p>
                        ) : (
                            <></>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicServices">
                        <div className='radio_inline_blue'>
                            <Form.Check
                            
                                inline
                                label="Save as your new standard prices"
                                name="group2"
                                type="checkbox"
                                // value={true}
                                defaultChecked={savePricesGlobal != null ? savePricesGlobal.save_videos : false}

                                onChange={(e) => setSavePricesGlobal((oldSearch) => ({...oldSearch, save_videos: e.target.checked}))}
                            />
                       
                    
                        </div>
                    </Form.Group>
                </Col>
                <Col lg={6} className="mt-5 mb-5">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h5 className="text-blue m-0"><b>Add discounts</b></h5>
                        <Button className='btn_outlined_blue btn_register m-0' onClick={addDiscount}>Add discount</Button>

                    </div>
                    <div id="div_discounts" className="mt-3">
                        {
                            
                            discounts.map((obj, index) =>{
                                return(
                                    <SetDiscount key={`${obj.property}-${index}`} index={index} discount={obj} discounts={discounts} setDiscounts={setDiscounts}  />
                                )
                            })
                        }

                    </div>
                    <div>
                        <Form.Group className="mb-3" controlId="formBasicServices">
                            <div className='radio_inline_blue'>
                                <Form.Check
                                
                                    inline
                                    label="Remember these discounts for the next albums."
                                    name="group1"
                                    type="checkbox"
                                    defaultChecked={saveDiscountsGlobal != null ? saveDiscountsGlobal : false}
                                    onChange={(e) => setSaveDiscountsGlobal(e.target.checked)}
                                
                                />
                                
                        
                            </div>
                        </Form.Group>
                        <OverlayTrigger 
                            placement="bottom"
                            overlay={
                            <Popover>
                                {/* <Popover.Header as="h3">Ttitle</Popover.Header> */}
                                <Popover.Body>
                                    <p className="text-grey">Buy 3 - <span className="text_light_blue"><b>Get 10% OFF</b></span></p>
                                    <p className="text-grey">Buy 5 - <span className="text_light_blue"><b>Get 20% OFF</b></span></p>
                                    <p className="text-grey">Buy 10 - <span className="text_light_blue"><b>Get 40% OFF</b></span></p>
                                </Popover.Body>
                            </Popover>}
                        
                        >
                            <a href="#" className="text_light_blue" onClick={(e) => {e.preventDefault()}}><b>View popular discounts</b></a>
                        </OverlayTrigger>

                    </div>
                
                </Col>
            </Row>

            {/* <div className="d-flex w-100 justify-content-between mb-5">
                <Button className='btn_outlined_blue btn_register me-auto ' onClick={onBack}>Back</Button>

                <Button className='btn_primary_blue btn_register ms-auto'  onClick={onNext} >Save and continue</Button>

            </div> */}

            <SuccessFulModal show={showModal} />
        </div>
        
    )
}

const CardImage = ({image}) => {
    return(
        <Card.Img className='card_round square mt-3' variant="top" src={image} />

    );
}

const LazyCardImage = lazy(() => Promise.resolve({default: CardImage}))



function SectionUploadPhotos({ setActiveCard, userInfo, filesSelected, setFilesSelected, productsGlobal, setProductsGlobal } ){

    const [accountDetails, setAccountDetails] = useState(SessionDetails())

    const [products, setProducts] = useState(productsGlobal.length != 0 ? productsGlobal : [])
    // const [filesSelected, setFilesSelected] = useState([])
    const [showLoadingModal, setShowLoadingModal] = useState(false)
    const [loadingProgress, setLoadingProgress] = useState(0)
    const [maxArray, setMaxArray] = useState()
    const [uploadedProducts, setUploadedProducts] = useState([])

    const [album, setAlbum] = useState({})

    const ic_delete_grey = require('../assets/ic_delete_grey_dark.png')

    const [coverSelectionIndexes, setCoverSelectionIndexes] = useState([])

    const [highlighted, setHighlighted] = useState(null);


    // const[photographerDefaultPricePhoto, setPhotographerDefaultPricePhoto] = useState()
    // const[photographerDefaultPriceVideo, setPhotographerDefaultPriceVideo] = useState()


    useEffect(() =>{
        setFilesSelected(products)
        setProductsGlobal(products)
    }, [products])




    function changeHightlight(index, value){
        // console.log(index + " " + value)
        var newArr = [...products]
        const previous_index = newArr.findIndex((element, prev_index) => index != prev_index && element.highlight == 1)

        // setHighlighted((prev) => (index !== prev && value === true ? index : null));
        

        if(previous_index != -1 && previous_index !== index && value === true){
            // console.log(previous_index)
            // console.log(index)
            newArr[previous_index].highlight = 0
            newArr[index].highlight = value === true ? 1 : 0

        }else{
            newArr[index].highlight = value === true ? 1 : 0

        }

        newArr[index].highlight = value === true ? 1 : 0
   


        setProducts(newArr)
    }

    function deleteProduct(e, index){
        e.preventDefault();
        var newArr = []
        for (let i = 0; i < products.length; i++) {
            const file = products[i]
            if (index !== i)
            newArr.push(file) // here you exclude the file. thus removing it.
          }

        setProducts(newArr)
    }


    return(
        <div>
            <Col lg={12} className="mt-5 ">
                <DragDropFile products={products} setProducts={setProducts} />
            </Col>

            <div className="mt-3 mb-5"> 
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="text-blue m-0"><b>Selected photos</b></h5>
                    {/* <Button className='btn_primary_blue btn_register mt-0 ms-auto'  onClick={clickUpload} >Upload Files</Button> */}

                </div>
                {/* <Row className='gx-4'> */}

                    <UploadFilesImagesSection album={album} deleteProduct={deleteProduct} changeHightlight={changeHightlight} products={products} />
 

                    {/* <UploadFilesImagesSection album={album} deleteProduct={deleteProduct} changeHightlight={changeHightlight} products={products} /> */}

                    {/* {products.map((obj, index) => {
                        var file_extension = products[index].file.name.split('.');
                        file_extension = file_extension[file_extension.length - 1];
                        products[index].album_id = album.album_id
                        products[index].photographer_id = album.photographer_id
                        products[index].location_id = album.location_id

                        if(!products[index].price){
                
                        }
                        

                        if(file_extension.toLowerCase() == "png" || file_extension.toLowerCase() == "jpg" || file_extension.toLowerCase() == "jpeg"){
                            products[index].product_type_id = 1
                            return (

                                <Col key={index} md={2} >
                                    <div className="card_img_upload">

                                   
                                        <LazyLoadImage src={obj.preview}
                                            effect="blur"
                                            className="card_round square mt-3"
                                            alt="Image Alt"
                                            scrollPosition={scrollPosition}

                                        />
                                        <img src={ic_delete_grey} alt="delete" className=" ic_delete_image" onClick={(e) => deleteProduct(e, index)} />
                                    
                                        <Form.Check
                                            className="mt-2"
                                            inline
                                            label="Show cover photo"
                                            type="checkbox"
                                            name={'input'+index}
                                            // value="1"
                                            id={"chkbox_hightlight_"+index}
                                            checked={products[index].highlight == true ? true : false}
                                            onChange={(e) => changeHightlight(index, e.target.checked)}
                                            
                                        />
    
                                    </div>
                                    
    
                                </Col>
    
                            );
                        }else if(file_extension.toLowerCase() == "mp4" || file_extension.toLowerCase() == "ogg" || file_extension.toLowerCase() == "mov"){
                            products[index].product_type_id = 2
                            return(
                                <Col key={index} md={2} >
                                    <div className="card_img_upload">
                                        <video className="card_round m-0 mt-3" >
                                            <source  src={obj.preview} type="video/mp4"/>
                                            <source  src={obj.preview} type="video/ogg"/>
                                            <source  src={obj.preview} type="video/mov"/>

                                            Your browser does not support the video tag.
                                        </video>
                                        <img src={ic_delete_grey} alt="delete" className=" ic_delete_image" onClick={(e) => deleteProduct(e, index)} />

                                        <Form.Check
                                            className="mt-2"
                                            inline
                                            label="Show cover photo"
                                            type="checkbox"
                                            name={'input'+index}
                                            // value="1"
                                            id={"chkbox_hightlight_"+index}
                                            // defaultChecked={index < 1 ? true : false}
                                            defaultChecked={products[index].highlight == true ? true : false}

                                            onChange={(e) => changeHightlight(index, e.target.checked)}
                                            
                                        />
                                        
    
                                    </div>
                                    
    
                                </Col>
                            )
                        }

                        
                    })} */}
                    
                {/* </Row> */}

            </div>
            {/* <div className="mt-3 mb-5">
                <h5 className="text-blue"><b>Uploaded photos</b></h5>
                <Row className='gx-4'>
                    
                    {uploadedProducts.map((obj, index) => {
                                                    // console.log(obj.media_format)

                        if(obj.media_format == "png" || obj.media_format == "jpg" || obj.media_format == "jpeg"){

                            return (

                                <Col key={index} md={2} >
                                    <div>
                                        <Card.Img className='card_round square mt-3' variant="top" src={obj.media_url} />
                                        <Form.Label className="m-0 mt-2">{`$${parseFloat(obj.price)/100}`}</Form.Label>
    
                                    </div>
                                    
    
                                </Col>
    
                            );
                        }else if(obj.media_format == "mp4" || obj.media_format == "ogg" || obj.media_format == "mov" ){
                            return(
                                <Col key={index} md={2} >
                                    <div>
                                        <video className="card_round mt-3" controls>
                                            <source  src={obj.media_url} type="video/mp4"/>
                                            <source  src={obj.media_url} type="video/ogg"/>
                                            <source  src={obj.media_url} type="video/mov"/>

                                            Your browser does not support the video tag.
                                        </video> 
                                        <Form.Label className="m-0">Price (AUD)</Form.Label>
                                        
                                    <Form.Control data-value={index} type="number" className='form-control-md w-100 mt-1 ' disabled placeholder='Price' defaultValue={obj.price/100} />
                                    </div>
                                    
                                </Col>
                            )
                        }

                        
                    })}
                </Row>
            </div> */}


            {/* <div className="d-flex w-100 justify-content-between mb-5">
                <Button className='btn_outlined_blue btn_register me-auto ' onClick={onBack}>Back</Button>

                <Button className='btn_primary_blue btn_register ms-auto'  onClick={onNext} >Save and continue</Button>

            </div> */}

            {/* <LoadingModal show={showLoadingModal} setShow={setShowLoadingModal} progress={loadingProgress} max={maxArray} setActiveCard={setActiveCard}/> */}
        </div>
        
        
    )
}



function Loading() {
    return <p>Loading...</p>;
  }


function SectionAlbumDetails({setActiveCard, userInfo, album, setAlbum, locationsGlobal, setLocationsGlobal}){
    const [ inputDate, setInputDate] = useState(album.album_datetime_start != null ? album.album_datetime_start.split(" ")[0] : '');
    const [ inputStartTime, setInputStartTime] = useState(album.album_datetime_start != null ? album.album_datetime_start.split(" ")[1] : '');
    const [ inputEndTime, setInputEndTime] = useState(album.album_datetime_end != null ? album.album_datetime_end.split(" ")[1] : '');

    const [location, setLocation] = useState({})
    const [cities, setCities] = useState()
    const [suburbs, setSuburbs] = useState()
    const [spots, setSpots] = useState()

    const [showLoadingModal, setShowLoadingModal] = useState(false)
    const [loadingProgress, setLoadingProgress] = useState(0)
    const [maxArray, setMaxArray] = useState()
    // const [album, setAlbum] = useState({photographer_id: userInfo.photographer_id})
    const [accountDetails, setAccountDetails] = useState(SessionDetails())
    // const [album, setAlbum] = useState({photographer_id: userInfo.photographer_id})
    const [locationsAlbum, setLocationsAlbums] = useState(locationsGlobal != null ? locationsGlobal : {})


    useEffect(() =>{
        setLocationsGlobal(locationsAlbum)
    }, [locationsAlbum])

    
    useEffect(() =>{
     
        getCities(locationsGlobal.location_state)
        getSpots(locationsGlobal.location_city)

        // getSpots(locationsGlobal.location_name)
    }, [])

    const array_states = [
        {
            val: 'QLD',
            text: "Queensland",

        },
        {
            val: "ACT",
            text: "Australian Capital Territory",

        },
        {
            val: "NSW",
            text: "New South Wales",

        },
        {
            val: "SA",
            text: "South Australia",

        },
        {
            val: "NT",
            text: "Northen Territory",

        },
        {
            val: "VIC",
            text: "Victoria",

        },
        {
            val: "TAS",
            text: "Tasmania",

        },
        {
            val: "WA",
            text: "Western Australia",

        },

    ]



    function onChangeState(value){
        if(value != null && value != ""){
            setLocationsAlbums((oldSearch) => ({...oldSearch, "location_state": value}))

            setLocationsAlbums((oldSearch) => ({...oldSearch, "location_city": ""}))
            setLocationsAlbums((oldSearch) => ({...oldSearch, "location_name": ""}))

            setCities([])
            // setSuburbs([])
            setSpots([])
            getCities(value)
        }else{
            setLocationsAlbums((oldSearch) => ({...oldSearch, "location_state": ""}))
            setLocationsAlbums((oldSearch) => ({...oldSearch, "location_name": ""}))
            setLocationsAlbums((oldSearch) => ({...oldSearch, "location_city":""}))

        }
    }

    function onChangeCity(value){
        if(value != null && value != ""){
            // setSuburbs([])
            setLocationsAlbums((oldSearch) => ({...oldSearch, "location_city": value}))

            setLocationsAlbums((oldSearch) => ({...oldSearch, "location_name": ""}))

            setSpots([])
            // getSuburbs(value)
            getSpots(value)
        }else{
            setLocationsAlbums((oldSearch) => ({...oldSearch, "location_city": ""}))

            setLocationsAlbums((oldSearch) => ({...oldSearch, "location_name": ""}))

        }
    }



    function onChangeSpot(value, name){
        // console.log(name)
        if(value != null && value != ""){

            setLocationsAlbums((oldSearch) => ({...oldSearch, "location_name": name}))

            setAlbum((oldSearch) => ({...oldSearch, "location_id": value}))

        }else{
            setLocationsAlbums((oldSearch) => ({...oldSearch, "location_name": ""}))

        }
    }

    

    function onChangeDate(e){
        // if(inputStartTime == "" || inputEndTime == ""){
            setInputDate(e.target.value)
        // }
    }

    function onChangeStartDateTime(time, timeString){
    
        // setInputStartTime(e.target.value)
        setInputStartTime(`${time.format("HH")}:${time.format("mm")}`)
    }

    function onChangeEndDateTime(time, timeString){
        // console.log(e.target.value)
        // setInputEndTime(e.target.value)
        setInputEndTime(`${time.format("HH")}:${time.format("mm")}`)
    }

    useEffect(() =>{
        
        if(inputDate != "" || inputStartTime != "" || inputEndTime != ""){
            // console.log("date modifiying")
            setAlbum((oldSearch) => ({...oldSearch, "album_datetime_start":  inputDate + " " + inputStartTime}))
            setAlbum((oldSearch) => ({...oldSearch, "album_datetime_end":  inputDate + " " + inputEndTime}))
        }
        


    }, [inputDate, inputStartTime, inputEndTime])



    function getCities(state){
        var query = `?location_state=${state}`;

        fetch(url_server + "public/locations/cities" + query, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then( (response) => response.json())
            
            .then( (data) => {
                // response = data.response

                setCities(data.response)

            })
            .catch((err) => {
                // console.log(err)
            });
    }


    

    function getSpots(suburb){
        var query = `?location_city=${suburb}`;

        fetch(url_server + "public/locations/spots" + query, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then( (response) => response.json())
            
            .then( (data) => {
                // response = data.response
                // // console.log(data.response)
                // console.log(locationsAlbum)

                setSpots(data.response)

            })
            .catch((err) => {
                // console.log(err)
            });
    }



    return(
        <div>

            <Col lg={5} className='mt-5 mb-5'>
                <h5 className="text-blue"><b>Album details</b></h5>
                <Form>
                    <Form.Group className="mb-3" >
                        <Form.Label className="fw-bold">State</Form.Label>
                        <Form.Select 
                            aria-label="Default select example" 
                            onChange={(e) => onChangeState(e.target.value)}
                            
                            required
                            // key={"OKAYG_" + (10000 + Math.random() * (1000000 - 10000))}
                            defaultValue={locationsGlobal.location_state}
                            >
                            <option value=""></option>
                            {array_states.map((obj, index) => {
                                return(
                                    <option key={index} value={obj.val}  >{obj.text}</option>
                                )
                            })}

                            {/* selected={album.location_state != null && album.location_state == obj.val ? "selected" : ""} */}
                            
                        </Form.Select>
                
                    </Form.Group>

                    {cities != null ? (
                        <Form.Group className="mb-3" >
                            <Form.Label className="fw-bold">Region</Form.Label>
                            {/* <Form.Control required  type="text" placeholder="Type here" /> */}
                            <Form.Select 
                                aria-label="Default select example" 
                                // onChange={(e) => setLocation((oldSearch) => ({...oldSearch, "location_city": e.target.value}))}
                                onChange={(e) => onChangeCity(e.target.value)}
                                required
                                defaultValue={locationsGlobal.location_city}

                                // key={"OKAYG_" + (10000 + Math.random() * (1000000 - 10000))}

                                // defaultValue={album.location_city}
                                >
                                <option value="" ></option>

                                {
                                    
                                    cities.map((obj, index) =>{
                                        return(
                                            <option key={index} value={obj.location_city}>{obj.location_city}</option>

                                        )
                                    })
                                }

                            
                            </Form.Select>
                    
                        </Form.Group>

                    ) : ("")}
                    
                    
                    {spots != null ? (
                        <Form.Group className="mb-3" >
                            <Form.Label className="fw-bold">Spot</Form.Label>
                            {/* <Form.Control required  type="text" placeholder="Type here" /> */}
                            <Form.Select 
                                aria-label="Default select example" 
                                onChange={(e) => onChangeSpot(e.target.value, e.target.options[e.target.selectedIndex].dataset.value)}
                                required
                                defaultValue={album.location_id}
                                // key={"OKAYG_" + (10000 + Math.random() * (1000000 - 10000))}

                                >
                                <option value=""></option>
                                {
                                    spots.map((obj, index) =>{
                                        return(
                                            <option key={index} data-value={obj.location_name} value={obj.location_id}>{obj.location_name}</option>

                                        )
                                    })
                                }

                                
                            </Form.Select>
                    
                        </Form.Group>

                    ) : ("")}
                    
                    <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">Date</Form.Label>

                        <Form.Control type="date" className='form-control-md search_personalized_date'  placeholder='Date' 
                            // onChange={(e) => setInputDate(e.target.value)}
                            onChange={onChangeDate}


                            // onFocus={(e) => (e.target.type = "date")}
                            // onBlur={(e) => (e.target.type = "text")} 
                            defaultValue={album.album_datetime_start != null ? album.album_datetime_start.split(" ")[0] : ""}
                            />


                    </Form.Group>

                    {/* <div className="d-flex w-100 mb-3">
                        <Form.Group className="me-2 w-100">
                            <Form.Label className="fw-bold">Start time</Form.Label>
                            <Form.Control 
                            type="time" 
                            className='form-control-md w-100'  
                            placeholder='Type Here' 
                            onChange={onChangeStartDateTime}
                            defaultValue={album.album_datetime_start != null ? album.album_datetime_start.split(" ")[1] : ""}

                        
                                />

                        </Form.Group>
                        <Form.Group className="ms-2 w-100">
                            <Form.Label className="fw-bold">End time</Form.Label>
                            <Form.Control 
                            type="time" 
                            className='form-control-md w-100'  
                            placeholder='Type Here' 
                            onChange={onChangeEndDateTime}
                            defaultValue={album.album_datetime_end != null ? album.album_datetime_end.split(" ")[1] : ""}

                            />

                        </Form.Group>
                    </div> */}

                    <div className="d-flex w-100 mb-3">
                       

                        <Form.Group className="me-2 w-100">
                            <Form.Label className="fw-bold">Start time</Form.Label>
                            
                            <TimePicker use12Hours format="h:mm A" 
                            className='form-control-md w-100'  
                            placeholder='Type Here'
                            size="large"
                            minuteStep="5"
                            needConfirm={false}
                            onChange={onChangeStartDateTime}
                            defaultValue={album.album_datetime_start != null ? Dayjs(album.album_datetime_start) : ""}
                            />

                        </Form.Group>
                        <Form.Group className="ms-2 w-100">
                            <Form.Label className="fw-bold">End time</Form.Label>
                           
                             <TimePicker use12Hours format="h:mm A" 
                            className='form-control-md w-100'  
                            placeholder='Type Here' 
                            size="large"
                            minuteStep="5"
                            needConfirm={false}
                            onChange={onChangeEndDateTime}
                            defaultValue={album.album_datetime_end != null ? Dayjs(album.album_datetime_end) : ""}
                            />

                        </Form.Group>
                    </div>

                    

                    <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">Category <span className="text_thin">(Select one)</span></Form.Label>

                        <div className='radio_inline_blue d-flex justify-content-between'>
                            <Form.Check
                                required
                                inline
                                label="Land"
                                name="group1"
                                type="radio"
                                value="Land"
                                defaultChecked={album.album_categories == "Land" ? true : false}

                                onClick={(e) => setAlbum((oldSearch) => ({...oldSearch, "album_categories": e.target.value}))}
                            />
                            <Form.Check
                                required
                                inline
                                label="Drone"
                                name="group1"
                                type="radio"
                                value="Drone"
                                defaultChecked={album.album_categories == "Drone" ? true : false}


                                onClick={(e) => setAlbum((oldSearch) => ({...oldSearch, "album_categories": e.target.value}))}
                            />
                            <Form.Check
                                required
                                inline
                                label="In water"
                                name="group1"
                                type="radio"
                                value="In water"
                                defaultChecked={album.album_categories == "In water" ? true : false}


                                onClick={(e) => setAlbum((oldSearch) => ({...oldSearch, "album_categories": e.target.value}))}
                            />
                            
                        </div>
                    </Form.Group>
                </Form>
            </Col>

            
            {/* <div className="d-flex w-100 justify-content-between mb-5">

                <Button className='btn_primary_blue btn_register ms-auto'  onClick={onNext} >Save and continue</Button>

            </div> */}
            {/* <LoadingModal show={showLoadingModal} setShow={setShowLoadingModal} progress={loadingProgress} max={maxArray} setActiveCard={setActiveCard}/> */}

        </div>
        
    )
}


function CreateAlbumHeader({userInfo}){
    const [userDetails, setUserDetails] = useState(SessionDetails())
    const [content, setContent] = useState()
    const [activeCard, setActiveCard] = useState({activeIndex: 1})

    // ALBUM TO SAVE LATER
    const [locationsAlbum, setLocationsAlbums] = useState({})
    const [album, setAlbum] = useState({photographer_id: userInfo.photographer_id})
    
    // FILES TO UPLOAD LATER
    const [productsGlobal, setProductsGlobal] = useState([])
    const [filesSelectedGlobal, setFilesSelectedGlobal] = useState([])
    const [showLoadingModal, setShowLoadingModal] = useState(false)
    const [loadingProgress, setLoadingProgress] = useState(0)
    const [maxArray, setMaxArray] = useState()
    const [modalLeaveSite, setModalLeaveSite] = useState(false)
    const [createAlbumId, setCreateAlbumId] = useState("")

    // DISCOUNTS
    const [pricesGlobal, setPricesGlobal] = useState({})
    const [savePricesGlobal, setSavePricesGlobal] = useState({save_photos: false, save_videos: false})
    // const [discountsGlobal, setDiscountsGlobal] = useState([{discount_quantity: "", discount_percentage: ""}])
    const [discountsGlobal, setDiscountsGlobal] = useState([])
    const [saveDiscountsGlobal, setSaveDiscountsGlobal] = useState(false)
    

    useEffect(() =>{
        getPhotographerDefaultDiscounts();
    },[])

    async function getPhotographerDefaultDiscounts(){
        fetch(`${url_server}private/photographers/${userInfo.photographer_id}/discounts` , {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': userDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {

                if(discountsGlobal.length == 0 && data.response.length > 0){
                    setDiscountsGlobal(data.response)
                }

            })
            .catch((err) => {
                // console.log(err)
            });
    }


    
    function onNext(value){
        
        if(value < activeCard.activeIndex){
            setActiveCard({activeIndex: value})
        }else{

            switch(activeCard.activeIndex){

                case 1:
                    if(!(album.photographer_id && album.location_id && album.album_datetime_start && !isNaN(Date.parse(album.album_datetime_start)) && album.album_datetime_end && !isNaN(Date.parse(album.album_datetime_end)) && album.album_categories)){
                        
                        alert("Please enter all details.\nIf you are creating the album through Safari, and already filled in all the requested information, please try to use another browser.")
                    }else if(Date.parse(album.album_datetime_start) >= Date.parse(album.album_datetime_end)){
                        alert("Start date cannot be after end date.")

                    }else if(new Date(album.album_datetime_start) > new Date() || new Date(album.album_datetime_end) > new Date()){
                        alert("Dates cannot be in the future")
                    }else{
                        setActiveCard({activeIndex: value})
                    }
                break;
    
                case 2:
                    if(filesSelectedGlobal != null && filesSelectedGlobal.length === 0){
                        alert("Please select photos and videos")
                    }else{

                        var highlight_count = 0

                        for(var i = 0; i < filesSelectedGlobal.length; i++){
                            if(filesSelectedGlobal[i].highlight == true){
                                highlight_count++
                            }

                        }

                        if(highlight_count === 0){
                            alert("You must to select a cover photo or video for your album")
                        }else if(highlight_count > 1){
                            alert("You only can select one image as a cover photo or video for your album")
                        }else{
                            setActiveCard({activeIndex: value})

                        }
                        
                    }
            
                break
    
                case 3:
    
                    // var finish = true;
                    // validatePrices()
                    // // console.log(pricesGlobal)
    
                    // if(Object.keys(pricesGlobal).length > 0){
                    //     // save prices into database
                    //     // await sendPrices()
                    // }
    
                    // finish = validateDiscounts()
                    // if(discountsGlobal.length > 0){
                    //     // await sendDiscounts()
                    // }
                    // validatePrices()
    
                    if( validateDiscounts() && validatePrices()){
                        // setShowModal(true)
                        setActiveCard({activeIndex: value})
    
                    }
                break
    
                case 4:

                    if(validatePhotosPrices()){
                        createAlbumTotal()
                    }

                break;
                default:
                break;
            }
        }
        
    }


    function validatePhotosPrices(){
        var isvalid = true
        for(var i = 0; i< filesSelectedGlobal.length; i++){
            if(filesSelectedGlobal[i].price == "" || filesSelectedGlobal[i].price == null){
                alert("Please, set a price to all photos and videos.")
                isvalid = false
            }

            if(filesSelectedGlobal[i].price < 0){
                alert("Price cannot be negative")
                isvalid = false
            }
        }


        return isvalid
    }


    function handleUnload(event){
        if(modalLeaveSite == false){
            event.returnValue = true;

        }
    }

    useEffect(() => {
        window.addEventListener('beforeunload', handleUnload)
        return () => window.removeEventListener('beforeunload', handleUnload)
    }, [modalLeaveSite])

    function onBack(e){
        
        if(activeCard.activeIndex != 1){
            setActiveCard({activeIndex: activeCard.activeIndex - 1})
        }else{
            // setModalLeaveSite(true)
            window.location.href = "/"+userDetails.user_unique_id
        }
    }

    function validateDiscounts(){
        if(discountsGlobal.length > 0){
            
            for(var i = 0; i < discountsGlobal.length; i++){
                if(discountsGlobal[i].discount_quantity == "" || discountsGlobal[i].discount_percentage == "" || isNaN(discountsGlobal[i].discount_percentage) ){
                    alert("Please set all discounts")
                    return false;
                }

                if(parseFloat(discountsGlobal[i].discount_percentage) > 0.99 || parseFloat(discountsGlobal[i].discount_percentage) < 0.01){
                    alert("Percentage needs to be between 1% and 99%")

                    return false;
                }

                if(parseFloat(discountsGlobal[i].discount_quantity) < 1){
                    alert("Products quantity needs to be bigger than 1")

                    return false;
                }
            }
        }
        return true;
    }

    function validatePrices(){
        var is_valid = true
        if(pricesGlobal != null){
            if(isNaN(pricesGlobal.photographer_default_price_photo)){
                delete pricesGlobal.photographer_default_price_photo
            }
    
            if(isNaN(pricesGlobal.photographer_default_price_video)){
                delete pricesGlobal.photographer_default_price_video
            }

            if(pricesGlobal.photographer_default_price_photo < 0){
                alert("Price cannot be negative")
                is_valid = false
            }

            if(pricesGlobal.photographer_default_price_video < 0){
                alert("Price cannot be negative")
                is_valid = false
            }
        }

        return is_valid
        

    }

   
    useEffect(() => {
        switch(activeCard.activeIndex){
            case 1:
                
                setContent(<SectionAlbumDetails 
                    setActiveCard={setActiveCard} 
                    userInfo={userInfo} 
                    album={album} 
                    setAlbum={setAlbum} 
                    locationsGlobal={locationsAlbum}
                    setLocationsGlobal={setLocationsAlbums}
                    />)

            break

            case 2:
              
                setContent(<SectionUploadPhotos  
                    setActiveCard={setActiveCard} 
                    userInfo={userInfo} 
                    productsGlobal={productsGlobal}
                    setProductsGlobal={setProductsGlobal} 
                    filesSelected={filesSelectedGlobal}
                    setFilesSelected={setFilesSelectedGlobal} 
                    
                    />)

            break;

            case 3:
                setContent(<SectionSetDiscounts  
                    setActiveCard={setActiveCard} 
                    userInfo={userInfo} 
                    pricesGlobal={pricesGlobal}
                    setPricesGlobal={setPricesGlobal}
                    discountsGlobal={discountsGlobal}
                    setDiscountsGlobal={setDiscountsGlobal}
                    productsGlobal={productsGlobal}
                    setProductsGlobal={setProductsGlobal}
                    savePricesGlobal={savePricesGlobal}
                    setSavePricesGlobal={setSavePricesGlobal}
                    saveDiscountsGlobal={saveDiscountsGlobal}
                    setSaveDiscountsGlobal={setSaveDiscountsGlobal}
                    />
                    
                    )
                    

            break;

            case 4:
                setContent(<SectionReview  userInfo={userInfo}
                    productsGlobal={productsGlobal}
                    setProductsGlobal={setProductsGlobal}
                    album={album}  
                    locations={locationsAlbum}
                    discountsGlobal={discountsGlobal}
                    pricesGlobal={pricesGlobal}
                    />)

            break;
            default:
            break

            
        }

    }, [activeCard]);

        
    function setView(e){
        setActiveCard({ activeIndex: parseInt(e.currentTarget.dataset.value)})

    }

    //############ START CREATING ALBUMS FROM HERE ###############

    async function createAlbumTotal(){

        // SAVE DEFAULT PRICES
        if(Object.keys(pricesGlobal).length > 0){
            // save prices into database
            const newPrices = {}

            if(pricesGlobal.photographer_default_price_photo != null && savePricesGlobal.save_photos == true){
                newPrices.photographer_default_price_photo = pricesGlobal.photographer_default_price_photo
            }
            if(pricesGlobal.photographer_default_price_video != null && savePricesGlobal.save_videos == true){
                newPrices.photographer_default_price_video = pricesGlobal.photographer_default_price_video

            }

            if(Object.keys(newPrices).length > 0){
                await sendPrices(newPrices)

            }

                
        }
        

        var obj_album = album
        // obj_album.album_datetime_start = new Date(obj_album.album_datetime_start)
        // obj_album.album_datetime_end = new Date(obj_album.album_datetime_end)

        await createAlbum(obj_album)

        // validatePrices()

         
    

    }

    async function createAlbum(obj){

        // IF ALBUMID IS IN THE URL, MODIFY THAT ALBUM ELSE CREATE IT
        setShowLoadingModal(true)

        
        fetch(url_server + "private/albums", {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': userDetails.token,
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                if(data.error){
                    alert(data.errorDescription)
                }else{
                    // console.log("Album created")
                    const album_id = data.response.insertId
                    setCreateAlbumId(album_id)
                    
                    if(discountsGlobal.length > 0){
                        // await sendDiscounts()
                        var discounts = discountsGlobal
                        for(var i = 0; i<discounts.length; i++){
                            discounts[i].album_id = album_id
                        }
                        sendDiscounts(discounts)

                        if(saveDiscountsGlobal){
                            var photographer_discounts = discountsGlobal
                            for(var i = 0; i<photographer_discounts.length; i++){
                                photographer_discounts[i].photographer_id = obj.photographer_id
                            }
                            deletePhotographerDefaultDiscounts(obj.photographer_id, photographer_discounts)
                        }

                    }

                    uploadFiles(album_id)
        
                }

            })
            .catch((err) => {
                // console.log(err)
            });
        
    }

    async function deletePhotographerDefaultDiscounts(photographer_id, discounts){
        fetch(`${url_server}private/photographers-discounts/${photographer_id }`, {
            method: 'PUT',
			body: JSON.stringify({discount_active: 0}),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
				'x-access-token': userDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
				if(data.error){
					alert("Something went wrong")
				}else{
                    sendPhotographerDefaultDiscounts(discounts)

				}

            })
            .catch((err) => {
                // console.log(err)
            });
    }

    async function sendPhotographerDefaultDiscounts(discounts){
        fetch(url_server + "private/photographers-discounts", {
            method: 'POST',
            body: JSON.stringify(discounts),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': userDetails.token,
            },
            })
            .then((response) => response.json())
            
            .then(async (data) => {
                // response = data.response
            
            })
            .catch((err) => {
                // console.log(err)
            });
    }

    async function sendDiscounts(discounts){
        fetch(url_server + "private/albums-discounts", {
            method: 'POST',
            body: JSON.stringify(discounts),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': userDetails.token,
            },
            })
            .then((response) => response.json())
            
            .then(async (data) => {
                // response = data.response
            
            })
            .catch((err) => {
                // console.log(err)
            });
    }

    async function sendPrices(newPrices){

        fetch(url_server + "private/photographers/"+userInfo.photographer_id, {
            method: 'PUT',
            body: JSON.stringify(newPrices),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': userDetails.token,
            },
            })
            .then((response) => response.json())
            
            .then(async (data) => {
                // response = data.response
            
            })
            .catch((err) => {
                // console.log(err)
            });
        
    }

    // OLD function
    function uploadFiles_old(album_id){

        const products = []
        for(var i=0;i<filesSelectedGlobal.length;i++){
            products[i] = {
                price: filesSelectedGlobal[i].price,
                album_id: album_id,
                product_type_id: filesSelectedGlobal[i].product_type_id,
                location_id: filesSelectedGlobal[i].location_id,
                photographer_id: filesSelectedGlobal[i].photographer_id,
                highlight: filesSelectedGlobal[i].highlight
            }

        }

        console.log(products)
        
        fetch(url_server + "private/products", {
            method: 'POST',
            body: JSON.stringify(products),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': userDetails.token,
            },
            })
            .then((response) => response.json())
            
            .then(async (data) => {
                // response = data.response

                var array_products = data.response
                setMaxArray(array_products.length)
                setLoadingProgress(0)

                for(var i =0; i<array_products.length; i++){
                    var product_id = array_products[i]
                    await onFileUpload(product_id, products[i], filesSelectedGlobal[i].file, album_id)
                    setLoadingProgress(i+1)
                    // console.log(loadingProgress)

                }

                setModalLeaveSite(true)
                await setAlbumVisible(album_id)
                setPricesGlobal([])
                setFilesSelectedGlobal([])
                if(loadingProgress<array_products.length){
                    setLoadingProgress(array_products.length)
                }


            })
            .catch((err) => {
                // console.log(err)
            });
        
        // create products

        

    }

    // TODO: REFACTOR THIS FUNCTION ASAP
    async function uploadFiles(album_id){

        setMaxArray(filesSelectedGlobal.length)
        setLoadingProgress(0)
        for(var i=0;i<filesSelectedGlobal.length;i++){
            let product = {
                price: filesSelectedGlobal[i].price,
                album_id: album_id,
                product_type_id: filesSelectedGlobal[i].product_type_id,
                location_id: filesSelectedGlobal[i].location_id,
                photographer_id: filesSelectedGlobal[i].photographer_id,
                highlight: filesSelectedGlobal[i].highlight
            }

            await fetch(url_server + "private/products", {
                method: 'POST',
                body: JSON.stringify(product),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'x-access-token': userDetails.token,
                },
                })
                .then((response) => response.json())
                
                .then(async (data) => {
                    // response = data.response
    
                    var response_product = data.response
                    
    
                    var product_id = response_product.insertId
                    await onFileUpload(product_id, product, filesSelectedGlobal[i].file, album_id)
                    setLoadingProgress(i+1)
                    // console.log(loadingProgress)
    
    
                })
                .catch((err) => {
                    // console.log(err)
                });

        }

        setModalLeaveSite(true)
        setAlbumVisible(album_id)
        setPricesGlobal([])
        setFilesSelectedGlobal([])
        if(loadingProgress<filesSelectedGlobal.length){
            setLoadingProgress(filesSelectedGlobal.length)
        }

        
        // create products

        

    }

    async function setAlbumVisible(album_id){
        fetch(url_server + "private/albums/"+album_id, {
            method: 'PUT',
            body: JSON.stringify({is_visible: 1}),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': userDetails.token,
            },
            })
            .then((response) => response.json())
            
            .then(async (data) => {
                // response = data.response
            
            })
            .catch((err) => {
                // console.log(err)
            });
    }

    async function onFileUpload(productId, product, file, album_id){
    
        // Create an object of formData
        const formData = new FormData();
        var file_extension = file.name.split('.');
        file_extension = file_extension[file_extension.length - 1];
        var dataProduct = {"extension": file_extension, "path": `album-products-${album_id}`}
        var dataMedia = {"extension": file_extension, "path": `album-media-${album_id}`}

//      save product image compressed
        if(file_extension.toLowerCase() == "jpg" || file_extension.toLowerCase() == "jpeg" || file_extension.toLowerCase() == "png"){

            const signed_url_response_no_watermark = await getSignedURL(dataProduct)
            const compressedResult = await ImageResizer(file)

            var uploaded = await uploadFile(signed_url_response_no_watermark.signedUrl, compressedResult, file)


            // save watermarked
            const watermarkCompressedResult = await ImageCompressor(file)
            var imgCompressedURL = URL.createObjectURL(watermarkCompressedResult);
                        
            try {
                const blob = await watermarkedImage(imgCompressedURL, userInfo.photographer_watermark_url);
                
                const signed_url_response_watermarked = await getSignedURL(dataProduct)
                if(signed_url_response_no_watermark != null && signed_url_response_watermarked != null){
        
                    var uploaded_watermarked = await uploadFile(signed_url_response_watermarked.signedUrl, blob, file)

                    if(uploaded && uploaded_watermarked){
                        await saveURL(productId, signed_url_response_no_watermark.fileUrl, signed_url_response_watermarked.fileUrl)
                    }
                }

                URL.revokeObjectURL(imgCompressedURL)


            } catch (error) {
                console.error(`Error while processing image`, error);
            }

            // save media original file
            const signed_url_response = await getSignedURL(dataMedia)
            if(signed_url_response != null){
                
                var uploadedOriginal = await uploadFile(signed_url_response.signedUrl, file, file)
                if(uploadedOriginal){
                    delete product.price
                    product.product_id = productId
                    product.media_url = signed_url_response.fileUrl
                    product.media_format = file_extension
                    product.media_size = file.size

                    await saveMedia(product)

                }
            }

        }else if(file_extension.toLowerCase() == "ogg" || file_extension.toLowerCase() == "mp4" || file_extension.toLowerCase() == "mov") {
            const signed_url_response = await getSignedURL(dataProduct)
            if(signed_url_response != null){
            
                var uploadedOriginalVideo = await uploadFile(signed_url_response.signedUrl, file, file)
                if(uploadedOriginalVideo){
                    await saveURL(productId, signed_url_response.fileUrl, "")

                    delete product.price
                    product.product_id = productId
                    product.media_url = signed_url_response.fileUrl
                    product.media_format = file_extension
                    product.media_size = file.size

                    await saveMedia(product)
    
                }
            }
        }
    
        
    };

    async function getSignedURL(data){
        var response;
        
        await fetch(url_server + "private/signedurl", {
            method: 'POST',
            body: JSON.stringify(
                // Add parameters here
                data
                ),
            mimeType: "multipart/form-data",
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                "x-access-token": userDetails.token,
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
            // GO TO SUCCESS PAGE
                // signed_url = data.response.signedUrl;
                // file_url = data.response.file_url;
                response = data.response

            })
            .catch((err) => {
                // console.log(err)
                alert("Something went wrong. Please check your connection and try again.");
            });

            return response;

        }

    async function uploadFile(signed_url, compressedFile, file){

        var uploaded = false;
        await axios.put(signed_url, compressedFile, {
            timeout: 0,
            mimeType: "multipart/form-data",
            headers: {
                "Content-Type": file.type,
                "x-amz-acl": "public-read",
            },

            onUploadProgress: (progressEvent) => {
                if (progressEvent.bytes) {
                    var progress = Math.round((progressEvent.loaded / progressEvent.total)*100)
                    if(progress == 100){
                        uploaded = true;
                    }
                    
                }
            },
            }).then((response) => {
                uploaded = true;
            })
            .catch((error) => {
                // Error
                if (error.response) {
                    
                    // console.log("Error")
                } else if (error.request) {
                    // console.log("Error")
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                    // console.log("Error")
                }
            });

        return uploaded;
    }

        async function saveURL(product_id, file_url, file_watermarked_url){

            const data = {"product_id": product_id, "media_url": file_url, "media_url_watermarked": file_watermarked_url}
            // console.log(data)

            await fetch(url_server + "private/products_media", {
                method: 'POST',
                body: JSON.stringify(data),
                
                // TODO: get access token from local storage instead
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    "x-access-token": userDetails.token,
                },
                })
                .then((response) => response.json())
                
                .then( (data) => {
                // GO TO SUCCESS PAGE
                    // console.log(data)
                })
                .catch((err) => {
                    // console.log(err)
                    alert("Something went wrong. Please check your connection and try again.");
                });
        }

        async function saveMedia(data){
            delete data.highlight
            await fetch(url_server + "private/media", {
                method: 'POST',
                body: JSON.stringify(data),
                
                // TODO: get access token from local storage instead
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    "x-access-token": userDetails.token,
                },   
                })
                .then((response) => response.json())
                
                .then( (data) => {
                // GO TO SUCCESS PAGE

                })
                .catch((err) => {
                    // console.log(err)
                    alert("Something went wrong. Please check your connection and try again.");
                });
        }



    return(
        <div className="div_albums">
            <h4><b>{window.location.pathname == "/create-album" ? "Create Album" : "Edit Album"}</b></h4>
            <Row className="mt-4 gx-3">
                <Col lg={3} className="mt-3">
                    {/* set onClickView in div to allow navigate threough on edit mode */}
                    {/* onClick={setView} */}
                    <div data-value={1} className={`personalized_card p-1 ${activeCard.activeIndex === 1 ? 'card_active' : ""}` } onClick={(e) => onNext(1)}>
                        <p className="text-thin p_xsmall ms-2">Step 1</p>
                        <p className="mb-1 mt-2 ms-2"><b>Add album details</b></p>
                    </div>

                    
                </Col>
                <Col lg={3}  className="mt-3">
                {/* onClick={setView} */}
                    <div data-value={2} className={`personalized_card p-1 ${activeCard.activeIndex === 2 ? 'card_active' : ""}` } onClick={(e) => onNext(2)}>
                        <p className="text-thin p_xsmall ms-2">Step 2</p>
                        <p className="mb-1 mt-2 ms-2"><b>Add photos</b></p>
                    </div>
                </Col>
                <Col lg={3} className="mt-3" >
                {/* onClick={setView} */}
                    <div data-value={3} className={`personalized_card p-1 ${activeCard.activeIndex === 3 ? 'card_active' : ""}` } onClick={(e) => onNext(3)}>
                        <p className="text-thin p_xsmall ms-2">Step 3</p>
                        <p className="mb-1 mt-2 ms-2"><b>Set prices and discounts</b></p>
                    </div>
                </Col>
                <Col lg={3} className="mt-3">
                    <div data-value={4} className={`personalized_card p-1 ${activeCard.activeIndex === 4 ? 'card_active' : ""}` } onClick={(e) => onNext(4)}>
                        <p className="text-thin p_xsmall ms-2">Step 4</p>
                        <p className="mb-1 mt-2 ms-2"><b>Review album</b></p>
                    </div>
                </Col>
            </Row>
            <div>
                {content}
            </div>
            <div className="d-flex w-100 justify-content-between mb-5">
                <Button className='btn_outlined_blue btn_register me-auto ' onClick={onBack}>Back</Button>

                <Button className='btn_primary_blue btn_register ms-auto'  onClick={(e) => onNext(parseInt(activeCard.activeIndex)+1)} >{activeCard.activeIndex != 4 ? "Next" : "Publish"}</Button>

            </div>

            <LoadingModal show={showLoadingModal} setShow={setShowLoadingModal} progress={loadingProgress} max={maxArray} setActiveCard={setActiveCard} album_id={createAlbumId}/>

            {/* <div className="d-flex w-100 justify-content-between mb-5">
                <Button className='btn_outlined_blue btn_register me-auto ' onClick={onBack}>Back</Button>

                <Button className='btn_primary_blue btn_register ms-auto'  onClick={onNext} >Next</Button>

            </div> */}
        </div>
    )
}

function CreateAlbumPage(){
    const [userDetails, setUserDetails] = useState(SessionDetails())
    const [userInfo, setUserInfo] = useState()

    // if(userInfo.photographer_id){
        
    // }
    

    // GET PHOTOGRAPHER DETAILS WHEN LOAD PAGE
    useEffect(() => {

        getPhotographerInfo();
        
        
    }, []);

    function getPhotographerInfo(){

        fetch(url_server + "private/photographers/" + userDetails.user_id , {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': userDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {

                setUserInfo(data.response[0])

            })
            .catch((err) => {
                // console.log(err)
            });
    
    }

    return(
        
        <div>
            <Navigation showSearchBar={0} />
            <Container fluid>
            {userInfo != null ? (
                <CreateAlbumHeader userInfo={userInfo} />

            ) : (
                ""
            )} 
                {/* <CreateAlbumHeader userInfo={userInfo} /> */}
            </Container>
        </div>
            
    )
    
}



    


export default CreateAlbumPage