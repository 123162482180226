import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { LazyLoadImage, trackWindowScroll  } from "react-lazy-load-image-component";
import Card from 'react-bootstrap/Card';
import LazyLoad from 'react-lazy-load';



function UploadFilesImagesSection({album, deleteProduct, changeHightlight, products, scrollPosition}){

    const ic_delete_grey = require('../assets/ic_delete_grey_dark.png')



    return(

        <Row className='gx-4'>
        {products.map((obj, index) => {
                var file_extension = products[index].file.name.split('.');
                file_extension = file_extension[file_extension.length - 1];
                products[index].album_id = album.album_id
                products[index].photographer_id = album.photographer_id
                products[index].location_id = album.location_id
                // products[index].highlight = index < 1 ? 1 : 0

                if(!products[index].price){
                    // if(file_extension == "png" || file_extension == "jpg" || file_extension == "jpeg"){
                    //     products[index].price = photographerDefaultPricePhoto
                    // }if(file_extension == "mp4" || file_extension == "ogg" || file_extension == "mov"){
                    //     products[index].price = photographerDefaultPriceVideo

                    // }
                }

              

            
                if(file_extension.toLowerCase() == "png" || file_extension.toLowerCase() == "jpg" || file_extension.toLowerCase() == "jpeg"){
                    products[index].product_type_id = 1
                    return (

                        <Col key={index} md={2} >
                            <div className="card_img_upload">

                                <Card.Img className='card_round square mt-3' variant="top" src={obj.preview} />

                                {/* <LazyLoadImage src={obj.preview}
                                    effect="blur"
                                    className="card_round square mt-3"
                                    alt="Image Alt"
                                    scrollPosition={scrollPosition}

                                /> */}
                                <img src={ic_delete_grey} alt="delete" className=" ic_delete_image" onClick={(e) => deleteProduct(e, index)} />
                            
                                <Form.Check
                                    className="mt-2"
                                    inline
                                    label="Show cover photo"
                                    type="checkbox"
                                    name={'input'+index}
                                    // value="1"
                                    id={"chkbox_hightlight_"+index}
                                    checked={products[index].highlight == true ? true : false}
                                    onChange={(e) => changeHightlight(index, e.target.checked)}
                                    
                                />

                            </div>
                            

                        </Col>


                    );
                }
            
                
                else if(file_extension.toLowerCase() == "mp4" || file_extension.toLowerCase() == "ogg" || file_extension.toLowerCase() == "mov"){
                    products[index].product_type_id = 2
                    return(
                        <Col key={index} md={2} >
                            <div className="card_img_upload">
                                <video className="card_round m-0 mt-3" >
                                    <source  src={obj.preview} type="video/mp4"/>
                                    <source  src={obj.preview} type="video/ogg"/>
                                    <source  src={obj.preview} type="video/mov"/>

                                    Your browser does not support the video tag.
                                </video>
                                <img src={ic_delete_grey} alt="delete" className=" ic_delete_image" onClick={(e) => deleteProduct(e, index)} />


                                {/* <InputGroup className="mt-2">
                                    <InputGroup.Text>Price</InputGroup.Text>
                                    <Form.Control data-value={index} type="number" className='form-control-md '  placeholder='Type Here' defaultValue={obj.price != "" ? parseFloat(obj.price)/100 : ""} onChange={(e) => changePrice(index, e.target.value)}/>

                                </InputGroup> */}
                                <Form.Check
                                    className="mt-2"
                                    inline
                                    label="Show cover photo"
                                    type="checkbox"
                                    name={'input'+index}
                                    // value="1"
                                    id={"chkbox_hightlight_"+index}
                                    // defaultChecked={index < 1 ? true : false}
                                    defaultChecked={products[index].highlight == true ? true : false}

                                    onChange={(e) => changeHightlight(index, e.target.checked)}
                                    
                                />
                                
                            {/* <Form.Control data-value={index} type="number" className='form-control-md w-100 mt-1'  placeholder='Price' defaultValue={obj.price} onChange={(e) => changePrice(index, e.target.value)}/> */}

                            </div>
                            

                        </Col>
                    )
                }

                
            })}

    </Row>

    )
}

export default UploadFilesImagesSection