import Navigation from "../components/Navigation"
import { Link, useParams, useLocation } from 'react-router-dom';
import { SessionDetails } from "../controllers/AccountController";

import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Footer } from '../components/Footer';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo, useState, useEffect } from "react";
import { google_maps_api_key, url_server } from '../settings';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import PhotoPurchaseCard from "../components/SectionPurchaseCard";
import Spinner from 'react-bootstrap/Spinner';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import CreateNewNextSessionModal from "../components/CreateNewSessionModal";
import { ListNextSessionsAdmin } from "../components/Lists";
import {useSearchParams} from 'react-router-dom';
import { ModalLandscapePhoto, ModalProfileDetails, ModalProfilePhoto, ModalContactDetails, ModalSocialNetworkInfo } from "../components/modals";




function Porfolio(){
    const img_wave = require('../assets/logo_green.png')

    return(
        

        <div>
            <img className=" mt-5 profile_wave d-block ms-auto me-auto" src={img_wave} alt="logo" /> 
            <h4 className="text-blue text-center mt-3" >Coming soon...</h4>

        </div>
    )
}

function SectionNextSessions({info, showModal}){
    const [nextSessions, setNextSessions] = useState()
    const img_wave = require('../assets/logo_green.png')
    const [createNewSessionModal, setCreateNewSessionModal] = useState(showModal ? true : false)
    const [session_details, setSessionsDetails] = useState(SessionDetails())

    var dayNames = [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
  ];
  var monthPrefixes = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      
  ];
    useEffect(() => {

        if(info.photographer_id != null ){
            getPhotographerNextSessions();

        }
        
    }, [info]);

    async function getPhotographerNextSessions(){
        // console.log(info.photographer_id)
        await fetch(`${url_server}public/next-sessions?photographer_id=${info.photographer_id}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                // console.log(data)
                setNextSessions(data.response)
    
            })
            .catch((err) => {
                // console.log(err)
                // alert("Something went wrong. Please check your connection and try again.");
            });
    }



    function goToCreateNextSessions(){
        // window.location.href = "/create-next-session"
        setCreateNewSessionModal(true)
    }



    return(
        <div>
            {nextSessions == null ? (
                <div className="text-center">
                    <Spinner animation="border" role="status" className="mt-5 ">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <div className="profile_last_sessions ">

                {nextSessions.length === 0 ? (
                    <>
                        {session_details.photographer_id == info.photographer_id ? (
                            <div>
                                <img className=" mt-5 profile_wave d-block ms-auto me-auto" src={img_wave} alt="logo" /> 
                                <p className="text_light_blue text-center mt-3" >Are you going to shoot a session?</p>
                                <Button className='btn_primary_blue  d-block ms-auto me-auto mt-3' onClick={(() => goToCreateNextSessions())}>Create a future session</Button>

                            </div>
                        ) : (
                            <div>
                                <img className=" mt-5 profile_wave d-block ms-auto me-auto" src={img_wave} alt="logo" /> 
                                <p className="text_light_blue text-center mt-3" >No future sessions added</p>

                            </div>
                        )}
                        
                    </>
                ) : (
                    <div > 

                        {session_details.photographer_id == info.photographer_id ? (

                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h5 className="text-blue m-0"><b>Your sessions</b></h5>
                                <Button className='btn_primary_blue  m-0' onClick={(() => goToCreateNextSessions())}>Create a future session</Button>

                            </div>
                        ) : (
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h5 className="text-blue m-0"><b>{info.photographer_business_name}'s sessions</b></h5>

                            </div>
                        )}
                        


                        <Col md={5} lg={5} xl={5} xxl={5} className='container_list_next_sessions'>
                            <ListNextSessionsAdmin nextSessions={nextSessions}  getPhotographerNextSessions={(e) => getPhotographerNextSessions()} />

                            {/* {nextSessions.map((dataObj, index) => {
                            {
                                
                                var start_time = new Date(dataObj.next_session_start_datetime).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
                                var end_time = new Date(dataObj.next_session_end_datetime).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
                                var day = new Date(dataObj.next_session_start_datetime).getDay()
                                var month = new Date(dataObj.next_session_start_datetime).getMonth()
                                var day_number = new Date(dataObj.next_session_start_datetime).toLocaleDateString().split("/")[0]

                            }

                            return (
                                <div key={index} className="">
                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start"  key={index}>
                                        <div className="ms-2 text-start div_list_date">
                                            <h2 className='text-blue p_bold'>{day_number}</h2>
                                            <p className='p_xlarge margin_5px '>{monthPrefixes[month]}</p>
                                        </div>
                                        <div className="ms-2 me-auto text-start">
                                            <p className="p_large p_bold">{dataObj.location_name}</p>
                                            <p className='text-thin p_small'>{dayNames[day]}, {start_time} - {end_time}</p>
                                            <p className='text-grey p_xsmall'>{dataObj.location_city} -  {dataObj.location_state}</p>
                                        </div>

                                    </ListGroup.Item>
                                    <hr/>
                                </div>
                                
                                );
                            })} */}
                            
                        </Col>
                        
                    </div>
                )}
                </div>
            )}
            <CreateNewNextSessionModal info={info} show={createNewSessionModal} setShow={setCreateNewSessionModal} getPhotographerNextSessions={(e) => getPhotographerNextSessions()}  />
        </div>

        
    )
}

function SectionLastSessions({info}){
    const img_wave = require('../assets/logo_green.png')
    const [albums, setAlbums] = useState();
    var [session_details, setSessionDetails] = useState(SessionDetails())
    
    
    useEffect(() => {

        if(info.photographer_id != null){
            getPhotographerSessions();

        }
        
    }, [info]);

    

    function goToCreateAlbums(){
        window.location.href = "/create-album?step=1"
    }


    function getPhotographerSessions(){
        fetch(url_server + "public/albums?photographer_id=" + info.photographer_id, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                // 'x-access-token': session_details.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                setAlbums(data.response)
    
            })
            .catch((err) => {
                // console.log(err)
            });
    }


    return(
        <div>
        {
            albums == null ? (
                <div className="text-center">
                    <Spinner animation="border" role="status" className="mt-5 ">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <div className="profile_last_sessions ">
                    {albums.length === 0 ? (
                        <div>
                            <img className=" mt-5 profile_wave d-block ms-auto me-auto" src={img_wave} alt="logo" /> 
                            <p className="text_light_blue text-center mt-3" >No photos albums added yet</p>
                            
                            {session_details.photographer_id == info.photographer_id ? (
                                <Button className='btn_primary_blue btn_register d-block ms-auto me-auto mt-3' onClick={(() => goToCreateAlbums())}>Create an Album</Button>

                            ) : ""}
        
                        </div>
                    ) : (
                        <div>
                            {session_details.photographer_id == info.photographer_id ? (
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="text-blue m-0"><b>Your albums</b></h5>

                                    
                                    <Button className='btn_primary_blue btn_register m-0' onClick={(() => goToCreateAlbums())}>Create an Album</Button>
            
                                </div>
                            ) : (
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="text-blue m-0"><b>{info.photographer_business_name}'s albums</b></h5>
            
                                </div>
                            )}

                            
                            <Container fluid className='mb-5 p-0'>
                                <Row className='gx-0'>
        
                                {albums.map((dataObj, index) => {
                                    return (
                                        // <PhotoPurchaseCard  key={dataObj.album_id} card_max_size={3} data={dataObj} linkTo={'/edit-album?step=1&album_id=' + dataObj.album_id  }/>
                                        <PhotoPurchaseCard  key={dataObj.album_id} card_max_size={3} data={dataObj} linkTo={'/album/' + dataObj.album_id} fromProfile={true}/>

                                    );
                                })}
        
                                </Row>
        
                            </Container>
                        </div>
                    )}
                </div>
            )
        }
                
                

        </div>
        
    )
    
}



function SectionPhotographersItems({info}){
    
    const tab = new URLSearchParams(useLocation().search).get("tab");

    const [accountDetails, setAccountDetaills] = useState(SessionDetails())

    const [pageContent, setPageContent] = useState(tab != null && tab == "next-sessions" ? 'nextsessionswithmodal' :'lastsessions')
    const ic_folder = require('../assets/ic_folder.png')
    const ic_video = require('../assets/ic_video_bigger.png')
    const ic_image = require('../assets/ic_image_bigger.png')


    useEffect(() => {
            const url = window.location.pathname; 
            window.history.pushState({}, document.title, url);
        
    }, [pageContent])


    const pages = {
        "lastsessions": <SectionLastSessions info={info} />,
        "nextsessionswithmodal": <SectionNextSessions info={info} showModal={true} />,
        "nextsessions": <SectionNextSessions info={info} showModal={false} />,

        "porfolio": <Porfolio />
        // "purchases": <SurferPurchases info={info} />
    }


    return(
        <div>
            <div className="div_photographer_items">
                <Nav  variant="tabs" defaultActiveKey={tab != null && tab == "next-sessions" ? "link-2" : "link-1"} >
                    <Nav.Item>
                        
                        <Nav.Link eventKey="link-1" className="personalized_menu_item" onClick={() => setPageContent("lastsessions")}><img src={ic_folder} alt="last sessions" className="me-2 mb-1"/>Last sessions</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="link-2" className="personalized_menu_item"  onClick={() => setPageContent("nextsessions")}><img src={ic_video} alt="last sessions" className="me-2 mb-1"/>Future sessions</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="link-3" className="personalized_menu_item" onClick={() => setPageContent("porfolio")}><img src={ic_image} alt="last sessions" className="me-2 mb-1"/>Porfolio</Nav.Link>
                    </Nav.Item>
         
                    
                </Nav>
            </div>
            <div>
                {pages[pageContent]}
                {/* <SectionLastSessions info={info} /> */}
            </div>
        </div>
        
        
    );
}

function DefaultLandscapeImage({info}){
    const img_logo_white = require('../assets/logo_writen_white.png')
    const img_click_photo = require('../assets/img_camera.png')
    const [accountDetails, setAccountDetaills] = useState(SessionDetails())
    const [modalPhotoShow, setModalPhotoShow] = useState(false)

    function uploadPhoto(photo_url){
        fetch(`${url_server}private/photographers/${info.photographer_id}`, {
            method: 'PUT',
            body: JSON.stringify({photographer_landscape_photo_url: photo_url}),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // console.log(data)
                if(data.error){
                    alert("Something went wrong")
    
                }else{
                    // alert("Your data has been updated successfully")
                    window.location.reload()

                }
               
    
            })
            .catch((err) => {
                // console.log(err)
            });
    }


    return(
        <div className="div_photographer_landscape_img">
            {info.photographer_landscape_photo_url != null && info.photographer_landscape_photo_url != "" ? (
                <div>
                   
                <img src={info.photographer_landscape_photo_url} alt="logo" className="img_landscape" />

                {accountDetails.photographer_id == info.photographer_id ? (
                    <div className="default_landscape_img_click">
                        <img src={img_click_photo} alt="chose photo" className="" onClick={() => setModalPhotoShow(true)} />

                    </div>

                ) : ""}
                 </div>

            ) : (
                <div>
                
                    <img src={img_logo_white} alt="logo" className="img_landscape_default"/>


                {accountDetails.photographer_id == info.photographer_id ? (
                    <div className="default_landscape_img_click">
                        <img src={img_click_photo} alt="chose photo" className="" onClick={() => setModalPhotoShow(true)} />

                    </div>

                ) : ""}

                </div>
            )}

            <ModalLandscapePhoto show={modalPhotoShow} setShow={setModalPhotoShow} onAccept={uploadPhoto} userInfo={info} />

        </div>
    )
}

function DefaultProfileImage({info, updateInfo}){
    const logo_green = require('../assets/logo_green.png')
    const img_click_photo = require('../assets/img_click_photo.png')
    const [modalPhotoShow, setModalPhotoShow] = useState(false)
    const [accountDetails, setAccountDetaills] = useState(SessionDetails())
	const ic_edit = require('../assets/ic_edit.png')
	const ic_mini_instagram = require('../assets/ic_mini_instagram.png')
    const [showModalAddContact, setShowModalAddContact] = useState(false)
    const [showSocialNetworks, setShowSocialNetworks] = useState(false)

    function uploadPhoto(photo_url){
        fetch(`${url_server}private/photographers/${info.photographer_id}`, {
            method: 'PUT',
            body: JSON.stringify({photographer_profile_photo_url: photo_url}),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                // // console.log(data.response)
                // console.log(data)
                // window.location.reload()
                if(data.error){
                    alert("Something went wrong")
    
                }else{
                    // alert("Your data has been updated successfully")
                    window.location.reload()

                }
               
    
            })
            .catch((err) => {
                // console.log(err)
            });
    }

    function addContactDetails(e){
        e.preventDefault();
        setShowModalAddContact(true)
    }

    function showModalSocialNetworks(e){
        e.preventDefault();
        setShowSocialNetworks(true)
    }

    return(
        <div className="mb-5">

            {info.photographer_profile_photo_url != null && info.photographer_profile_photo_url != "" ? (
                <div className="round_profile_picture">
                    <img src={info.photographer_profile_photo_url} alt="profile" className="w-100 default_profile_img_select" />

                    {/* only logged in */}
                    {accountDetails.photographer_id == info.photographer_id ? (
                        <img src={img_click_photo} alt="chose photo" className="w-100 default_profile_img_click" onClick={() => setModalPhotoShow(true)} />

                    ) : ""}

                    
                </div>  
            ): (
                <div className="round_profile_picture">
                    <img src={logo_green} alt="profile" className="w-100 default_profile_img" />

                    {/* ONLY LOGGED IN */}
                    {accountDetails.photographer_id == info.photographer_id ? (
                        <img src={img_click_photo} alt="chose photo" className="w-100 default_profile_img_click" onClick={() => setModalPhotoShow(true)} />

                    ) : ""}

                </div>
            )}
            
                <div className="mt-4 d-flex justify-content-center">
                    {/* <div>
                        <img src={ic_mini_instagram} alt="instagram" />
                        <a href={`https://instagram.com/${info.photographer_instagram}`} className="text-blue ms-2 me-2">{info.photographer_instagram}</a>
                    </div> */}

                    {accountDetails.photographer_id == info.photographer_id ? (
                        <p className="text-blue ms-2 me-2 mb-0 underlined clickable" onClick={(e) => addContactDetails(e)}><b>Edit contact details</b></p>

                    ) : (
                        <p className="text-blue ms-2 me-2 mb-0 underlined clickable" onClick={(e) => showModalSocialNetworks(e)}><b>Connect with {info.photographer_business_name}</b></p>

                    )}
                    

                </div>

            <ModalContactDetails show={showModalAddContact} setShow={setShowModalAddContact} userInfo={info} onAccept={updateInfo} />
            <ModalSocialNetworkInfo show={showSocialNetworks} setShow={setShowSocialNetworks} userInfo={info} />
            <ModalProfilePhoto show={modalPhotoShow} setShow={setModalPhotoShow} onAccept={uploadPhoto} userInfo={info} />
        </div>
    )
}



function SectionPhotographerInfo({info}){

    const [showModalEdit, setShowModalEdit] = useState(false)
    const [accountDetails, setAccountDetaills] = useState(SessionDetails())

    const ic_location = require("../assets/ic_location_card.png");
    const img = require('../assets/photo_card_example.jpg')
	const ic_edit = require('../assets/ic_edit.png')
    const ic_wave_blue = require('../assets/ic_wave_blue.png')
    const ic_comas = require('../assets/ic_comas.png')

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener("resize", () => {
            setScreenWidth(window.innerWidth)
        });
        return () => {
            window.removeEventListener("resize", () => {
                setScreenWidth(window.innerWidth)
            })
        }
    }, []);

    function updateInfo(data){
        if(data != null && Object.keys(data).length > 0){
            fetch(`${url_server}private/photographers/${info.photographer_id}`, {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'x-access-token': accountDetails.token
                },
                })
                .then((response) => response.json())
                
                .then( (data) => {
                    // response = data.response
                    // // console.log(data.response)
                    // console.log(data)
                    // window.location.reload()
                    if(data.error){
                        alert("Something went wrong")
        
                    }else{
                        // alert("Your data has been updated successfully")
                        window.location.reload()

                    }
                   
        
                })
                .catch((err) => {
                    // console.log(err)
                });
        }

    }

    return(
        <div>
            <DefaultLandscapeImage info={info} />
            <div className="div_photographer_profile ">
                <DefaultProfileImage info={info} updateInfo={updateInfo} />
               
                <div className="div_photographer_details">
                    <div className="d-flex align-items-center">
                        <h2 className="font_gopher text-dark ">{info.photographer_business_name}</h2>
                        {/* ONLY LOGGED IN */}
                        {accountDetails.photographer_id == info.photographer_id ? (
                            <a href="#" className="text-center text-blue ms-3" onClick={(e) => setShowModalEdit(true)}><b>Edit details</b></a>
                        ) : ""}

                    </div>

                    {(info.photographer_public_city != null && info.photographer_public_city != "") || (info.photographer_public_state != null && info.photographer_public_state != "") ?(
                        <div className='mt-2'>
                            <img src={ic_location} alt="location" className='pb-1'/>
                            <p className='text-thin text_inline ms-1'>{info.photographer_public_city} {info.photographer_public_city != null && info.photographer_public_city != "" && info.photographer_public_state != null && info.photographer_public_state != "" ? " - ": ""} {info.photographer_public_state}</p>
                        </div>
                    ):(
                        <></>
                    )}

                        
                    {accountDetails.photographer_id == info.photographer_id ? (
                        <div className="d-flex mt-2">
                            <div><img src={ic_comas} alt="comas" /></div>
                            <p className="text-grey p_small">{info.photographer_description != null && info.photographer_description != "" ? info.photographer_description : "Write something about you"}</p>
                        </div>
                    ):(
                        info.photographer_description != null && info.photographer_description != "" ?(
                            <div className="d-flex mt-2">

                                <div><img src={ic_comas} alt="comas" /></div>
                                <p className="text-grey p_small">{info.photographer_description}</p>
                            </div>

                        ):(
                            ""
                        )
                        
                    )}


                    <div className='mt-2 d-flex vertical-align-center'>
                        {/* <img src={ic_location} alt="location" className='pb-1'/> */}
                        <p className="m-0  p_large text_inline">You can find me at:</p>
                        {info.photographer_main_spots != null && JSON.parse(info.photographer_main_spots).map((obj, index) =>{
                            return(
                            
                                <span key={index} className="badge badge_personalized ms-1"><img src={ic_wave_blue} alt="icon" /> {obj}</span>
                                
                            )

                        })}
                        
                    </div>

                    <div className='mt-2 d-flex vertical-align-center'>
                        {/* <img src={ic_location} alt="location" className='pb-1'/> */}
                        <p className="m-0  p_large text_inline">What I offer: </p>
                        {info.photographer_products_category != null && JSON.parse(info.photographer_products_category).map((obj, index) =>{
                            return(
                            
                                <span key={index} className="badge badge_personalized ms-1"><img src={ic_wave_blue} alt="icon" /> {obj}</span>
                                
                            )

                        })}
                        <span className="vertical_line ms-2 me-1"></span>

                        {info.photographer_products_type != null && JSON.parse(info.photographer_products_type).map((obj, index) =>{
                            return(
                            
                                <span key={index} className="badge badge_personalized ms-1"><img src={ic_wave_blue} alt="icon" /> {obj}</span>
                                
                            )

                        })}

                       
                    </div>
                </div>
            </div>
            <ModalProfileDetails show={showModalEdit} setShow={setShowModalEdit} userInfo={info} onAccept={updateInfo} />
        </div>
    )
} 


function Account(){

    const [info, setInfo] = useState()
    const { user_unique_id } = useParams();
    var accountDetails = SessionDetails()


    useEffect(() => {
        getPhotographerInfo();
       
    }, []);

    function getPhotographerInfo(){
        fetch(url_server + "public/users/" + user_unique_id +"/photographer", {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                // 'x-access-token': session_details.token
            },
            })
            .then((response) => response.json())
            
            .then( (data) => {
                // response = data.response
                // console.log(data.response)
                setInfo(data.response)

            })
            .catch((err) => {
                // console.log(err)
            });
    
    }

    return(
        <>
        {info != null ? (
            <>
            {accountDetails.photographer_id == info[0].photographer_id ? (
                <Navigation showSearchBar={0} />
            ) : (
                <Navigation showSearchBar={1} />
            )}
            {info.length == 0 ? (
                <div className="div_albums">
                    <div>
                        <h4 className="text-blue text-center mt-5" >You don't have a photographer profile</h4>
                    </div>
                </div>
            ) : (
                <div>
                    <Container fluid className="p-0" id="container_account">
                        <SectionPhotographerInfo info={info[0]} />
                        <SectionPhotographersItems info={info[0]} />
                    </Container>
                </div>
            )}
            </>
        ) : (
            <div className="text-center">
                <Spinner animation="border" role="status" className="mt-5 ">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        )}
        </>
    )
}

export default Account