
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useMemo, useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import { url_server } from '../settings';
import { SessionDetails } from '../controllers/AccountController';




function CreateNewNextSessionModal({info, show, setShow, getPhotographerNextSessions}){
    // const [inputDate, setInputDate] = useState('');
    const [cities, setCities] = useState([])
    const [suburbs, setSuburbs] = useState([])
    const [spots, setSpots] = useState([])
    const [nextSession, setNextSession] = useState({photographer_id: info.photographer_id})
    const [productTypes, setProductTypes] = useState([]);

    const [ inputDate, setInputDate] = useState('');
    const [ inputStartTime, setInputStartTime] = useState('');
    const [ inputEndTime, setInputEndTime] = useState('');


    const array_states = [
        {
            val: 'QLD',
            text: "Queensland",

        },
        {
            val: "ACT",
            text: "Australian Capital Territory",

        },
        {
            val: "NSW",
            text: "New South Wales",

        },
        {
            val: "SA",
            text: "South Australia",

        },
        {
            val: "NT",
            text: "Northen Territory",

        },
        {
            val: "VIC",
            text: "Victoria",

        },
        {
            val: "TAS",
            text: "Tasmania",

        },
        {
            val: "WA",
            text: "Western Australia",

        },

    ]

    function onChangeState(value){
        setCities([])
        setSpots([])

        if(value != null && value != ""){
            getCities(value)

        }
    }

    function onChangeCity(value){
        setSpots([])

        if(value != null && value != ""){
            getSpots(value)
        }
    }



    function onChangeSpot(value){
        // if(value != null && value != ""){
            setNextSession((oldSearch) => ({...oldSearch, "location_id": value}))

        // }
    }

    // function onChangeStartDateTime(e){
    //     if(inputDate !== ""){
    //         var datetime = inputDate + " " + e.target.value
    //         setNextSession((oldSearch) => ({...oldSearch, "next_session_start_datetime": datetime}))

    //     }
    // }

    // function onChangeEndDateTime(e){
    //     if(inputDate !== ""){
    //         var datetime = inputDate + " " + e.target.value
    //         setNextSession((oldSearch) => ({...oldSearch, "next_session_end_datetime": datetime}))

    //     }
    // }

    const handleProductType = (event) => {
        if(event.target.checked && !productTypes.includes(event.target.value)){
            productTypes.push(event.target.value)
        }else if(!event.target.checked && productTypes.includes(event.target.value)){
            productTypes.splice(productTypes.indexOf(event.target.value), 1);

        }
        
        if(!event.target.checked && productTypes.length === 0){
            // console.log("entra")
            var obj = nextSession
            delete obj.product_types_id
            setNextSession(obj)
            return

        }
        
        setNextSession((oldSearch) => ({...oldSearch, "product_types_id": JSON.stringify(productTypes)}))
    }

    function getCities(state){
        // console.log(state)
        var query = `?location_state=${state}`;

        fetch(url_server + "public/locations/cities" + query, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
			},
			})
			.then( (response) => response.json())
			
			.then( (data) => {
				// response = data.response

				setCities(data.response)

			})
			.catch((err) => {
				// console.log(err)
			});
    }


    

    function getSpots(suburb){
        var query = `?location_city=${suburb}`;

        fetch(url_server + "public/locations/spots" + query, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
			},
			})
			.then( (response) => response.json())
			
			.then( (data) => {
				// response = data.response
				// // console.log(data.response)

				setSpots(data.response)

			})
			.catch((err) => {
				// console.log(err)
			});
    }



    function handleClose(){
        
        setNextSession({photographer_id: info.photographer_id})
        setInputDate('')
        setCities([])
        setSuburbs([])
        setSpots([])
        
        setShow(false);


    } 

    useEffect(() =>{
        
        if(inputDate != "" || inputStartTime != "" || inputEndTime != ""){
            // console.log("date modifiying")
            setNextSession((oldSearch) => ({...oldSearch, "next_session_start_datetime":  inputDate + " " + inputStartTime}))
            setNextSession((oldSearch) => ({...oldSearch, "next_session_end_datetime":  inputDate + " " + inputEndTime}))
        }
        


    }, [inputDate, inputStartTime, inputEndTime])


    function validateData(){

        // console.log(nextSession)
        if(!(nextSession.location_id && nextSession.next_session_start_datetime && nextSession.next_session_end_datetime && nextSession.next_session_category && nextSession.product_types_id) && !isNaN(Date.parse(nextSession.next_session_start_datetime)) && !isNaN(Date.parse(nextSession.next_session_end_datetime))){
            alert("All details need to be filled")
            return false
        }
        if(Date.parse(nextSession.next_session_start_datetime) >= Date.parse(nextSession.next_session_end_datetime)){
            alert("Start date cannot be after end date.")
            return false
            
        }
        if(new Date(nextSession.next_session_start_datetime) <= new Date() || new Date(nextSession.next_session_end_datetime) <= new Date()){
            alert("Dates cannot be in the past")
            return false
        }

        // console.log("no problem")

        return true

    }

    async function handleCreate(){

        if(validateData()){
            var objNS = nextSession
            objNS.next_session_start_datetime = new Date(nextSession.next_session_start_datetime)
            objNS.next_session_end_datetime = new Date(nextSession.next_session_end_datetime)


            await fetch(url_server + "private/next-sessions", {
                method: 'POST',
                body: JSON.stringify(
                    // Add parameters here
                    objNS
                  ),
                mimeType: "multipart/form-data",
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    "x-access-token": SessionDetails().token,
                },
                })
                .then((response) => response.json())
                
                .then( (data) => {
                // GO TO SUCCESS PAGE
                    if(data.error){
                        alert("Something went wrong")
                    }else{
                        // window.location.reload();
                        setShow(false)
                        getPhotographerNextSessions()
                    }
    
                })
                .catch((err) => {
                    // console.log(err)
                    alert("Something went wrong. Please check your connection and try again.");
                });

        }


    }


    return (
        <>

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
            <Modal.Title>New Photo / Video Session</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form>
                    <Form.Group className="mb-3" >
                        <Form.Label className="fw-bold">State</Form.Label>
                        <Form.Select 
                            aria-label="Default select example" 
                            onChange={(e) => onChangeState(e.target.value)}
                            required
                            >
                            <option value=""></option>
                            {array_states.map((obj, index) => {
                                return(
                                    <option key={index} value={obj.val}  >{obj.text}</option>
                                )
                            })}

                            
                        </Form.Select>
                
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label className="fw-bold">Region</Form.Label>
                        <Form.Select 
                            aria-label="Default select example" 
                            onChange={(e) => onChangeCity(e.target.value)}
                            required

                            >
                            <option value="" ></option>

                            {
                                
                                cities.map((obj, index) =>{
                                    return(
                                        <option key={index} value={obj.location_city}>{obj.location_city}</option>

                                    )
                                })
                            }

                        
                        </Form.Select>
                
                    </Form.Group>
                    
                    <Form.Group className="mb-3" >
                        <Form.Label className="fw-bold">Spot</Form.Label>
                        <Form.Select 
                            aria-label="Default select example" 
                            onChange={(e) => onChangeSpot(e.target.value)}
                            required
                            >
                            <option value=""></option>
                            {
                                spots.map((obj, index) =>{
                                    return(
                                        <option key={index} value={obj.location_id}>{obj.location_name}</option>

                                    )
                                })
                            }

                            
                        </Form.Select>
                
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">Date</Form.Label>

                        <Form.Control type="date" className='form-control-md search_personalized_date'  placeholder='Date' 
                            onChange={(e) => setInputDate(e.target.value)}
                            // onFocus={(e) => (e.target.type = "date")}
                            // onBlur={(e) => (e.target.type = "text")} 

                            />


                    </Form.Group>

                    <div className="d-flex w-100 mb-3">
                        <Form.Group className="me-2 w-100">
                            <Form.Label className="fw-bold">Start time</Form.Label>
                            <Form.Control 
                            type="time" 
                            className='form-control-md w-100'  
                            placeholder='Type Here' 
                            onChange={(e) => setInputStartTime(e.target.value)}
       
                             />

                        </Form.Group>
                        <Form.Group className="ms-2 w-100">
                            <Form.Label className="fw-bold">End time</Form.Label>
                            <Form.Control 
                            type="time" 
                            className='form-control-md w-100'  
                            placeholder='Type Here' 
                            onChange={(e) => setInputEndTime(e.target.value)}
 
                            />

                        </Form.Group>
                    </div>

                    <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">Category <span className="text_thin">(Select one)</span></Form.Label>

                        <div className='radio_inline_blue d-flex justify-content-between'>
                            <Form.Check
                                required
                                inline
                                label="Land"
                                name="group1"
                                type="radio"
                                value="Land"
                 

                                onClick={(e) => setNextSession((oldSearch) => ({...oldSearch, "next_session_category": e.target.value}))}
                            />
                            <Form.Check
                                required
                                inline
                                label="Drone"
                                name="group1"
                                type="radio"
                                value="Drone"
                

                                onClick={(e) => setNextSession((oldSearch) => ({...oldSearch, "next_session_category": e.target.value}))}
                            />
                            <Form.Check
                                required
                                inline
                                label="In water"
                                name="group1"
                                type="radio"
                                value="In water"

                                onClick={(e) => setNextSession((oldSearch) => ({...oldSearch, "next_session_category": e.target.value}))}
                            />
                            
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">Category <span className="text_thin">(Select one)</span></Form.Label>

                        <div className='radio_inline_blue d-flex '>
                            <Form.Check
                                required
                                inline
                                label="Photos"
                                name="group2"
                                type="checkbox"
                                value="1"
                 

                                onClick={handleProductType}
                            />
                            <Form.Check
                                required
                                inline
                                label="Videos"
                                name="group2"
                                type="checkbox"
                                value="2"
                

                                onClick={handleProductType}
                            />
                           
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex w-100 justify-content-between'>
                    <Button className='btn_outlined_blue btn_register'  onClick={handleClose}>Close</Button>
                    <Button className='btn_primary_blue btn_register'  onClick={handleCreate}>Create</Button>
                </div>
                

            </Modal.Footer>
        </Modal>
        </>
    );
}

export default CreateNewNextSessionModal