
import { SessionDetails } from "../controllers/AccountController"
import Navigation from "../components/Navigation"
import { Link, useParams, useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Footer } from '../components/Footer';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import React, { useCallback, useMemo, useState, useEffect, Component, useId } from "react";
import { google_maps_api_key, url_server } from '../settings';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import DragDropFile, { DragDropFileSingle } from "../components/DragDropFile";
import Compressor from 'compressorjs';
import axios from 'axios';
import SetDiscount from "../components/Discounts";
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import InputGroup from 'react-bootstrap/InputGroup';
// import { WithContext as ReactTags } from 'react-tag-input';
// import { ReactTags } from 'react-tag-autocomplete'
import { TagsInput } from "react-tag-input-component";
import { ReactTags } from 'react-tag-autocomplete'

import ProductSales from "./ProductSales";
import Spinner from 'react-bootstrap/Spinner';



function BrandNameError({ content }) {
    return (
        <Form.Text className="text-danger">
            {content}
        </Form.Text>
    );
}

function ModalConfirmPassword({ show, setShow, onAccept, userInfo }) {
    const [data, setData] = useState()


    function handleClose() {
        setShow(false);
        setData()
    }

    function handleAccept() {

        setShow(false)
        onAccept(data)
    }


    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="p-0"
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm old password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>


                        <Form.Group className="mb-3" >
                            <Form.Label className="fw-bold ">Old password</Form.Label>
                            <Form.Control type="password" placeholder="Type here" />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button className='btn_outlined_blue btn_register' onClick={handleClose}>Close</Button>
                    <Button className='btn_primary_blue btn_register ' onClick={handleAccept}>Change password</Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

function ModalSocialNetworkInfo({ show, setShow, userInfo }) {
    const [data, setData] = useState()

    function handleClose() {
        setShow(false);
        setData()
    }

    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="p-0"
                size="sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Social Networks</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">

                    {userInfo.photographer_instagram != null && userInfo.photographer_instagram != "" ? (
                        <div className="pb-3 pt-3 ps-2 pe-2 border_bottom_05">
                            <a href={"https://instagram.com/" + userInfo.photographer_instagram} className="fw-bold text-blue">Instagram</a>
                        </div>
                    ) : ""}

                    {userInfo.photographer_facebook != null && userInfo.photographer_facebook != "" ? (
                        <div className="pb-3 pt-3 ps-2 pe-2 border_bottom_05">
                            <a href={userInfo.photographer_facebook} className="fw-bold text-blue">Facebook</a>
                        </div>
                    ) : ""}

                    {userInfo.photographer_website != null && userInfo.photographer_website != "" ? (
                        <div className="pb-3 pt-3 ps-2 pe-2 border_bottom_05">
                            <a href={userInfo.photographer_website} className="fw-bold text-blue">Website</a>
                        </div>
                    ) : ""}

                    {userInfo.photographer_pinterest != null && userInfo.photographer_pinterest != "" ? (
                        <div className="pb-3 pt-3 ps-2 pe-2 border_bottom_05">
                            <a href={userInfo.photographer_pinterest} className="fw-bold text-blue">Pinterest</a>
                        </div>
                    ) : ""}

                    {userInfo.photographer_contact_email != null && userInfo.photographer_contact_email != "" ? (
                        <div className="pb-3 pt-3 ps-2 pe-2 border_bottom_05">
                            <p className="fw-bold text-blue">{userInfo.photographer_contact_email}</p>
                        </div>
                    ) : ""}



                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button className='btn_outlined_blue btn_register' onClick={handleClose}>Close</Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

function ModalContactDetails({ show, setShow, onAccept, userInfo }) {
    const [data, setData] = useState()


    function handleClose() {
        setShow(false);
        setData()
    }


    function handleAccept() {

        setShow(false)
        onAccept(data)

    }


    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="p-0"
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Your details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicSocialMedia">
                            <Form.Label className="fw-bold ">Instagram </Form.Label>

                            <InputGroup >

                                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                <Form.Control defaultValue={userInfo.photographer_instagram} type="text" placeholder="Type Here" onChange={(e) => setData((oldSearch) => ({ ...oldSearch, "photographer_instagram": e.target.value }))} />

                            </InputGroup>
                            <div id="emailHelp" className="form-text">This will be shown in your albums to promote your social media on the platform</div>


                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label className="fw-bold ">Facebook URL</Form.Label>
                            <Form.Control type="text" placeholder="Type here" defaultValue={userInfo.photographer_facebook} onChange={(e) => setData((oldSearch) => ({ ...oldSearch, "photographer_facebook": e.target.value }))} />
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label className="fw-bold ">Personal Website URL</Form.Label>
                            <Form.Control type="text" placeholder="Type here" defaultValue={userInfo.photographer_website} onChange={(e) => setData((oldSearch) => ({ ...oldSearch, "photographer_website": e.target.value }))} />
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label className="fw-bold ">Pinterest URL</Form.Label>
                            <Form.Control type="text" placeholder="Type here" defaultValue={userInfo.photographer_pinterest} onChange={(e) => setData((oldSearch) => ({ ...oldSearch, "photographer_pinterest": e.target.value }))} />
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label className="fw-bold ">Contact Email</Form.Label>
                            <Form.Control type="text" placeholder="Type here" defaultValue={userInfo.photographer_contact_email} onChange={(e) => setData((oldSearch) => ({ ...oldSearch, "photographer_contact_email": e.target.value }))} />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button className='btn_outlined_blue btn_register' onClick={handleClose}>Close</Button>
                    <Button className='btn_primary_blue btn_register ' onClick={handleAccept}>Apply</Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}


// MOVEMENT PARAMETER CAN BE A GROUP OF MOVEMETNS GROUPED BY PAYMENT_ID OR A SINGLE MOVEMENT. ORGANISE INFO DEPENDING IF RECEIVED MOVEMENT_ID OR PAYMENT_ID
function ModalMovementDetails({ show, setShow, movement }) {
    const [accountDetails, setAccountDetaills] = useState(SessionDetails())
    const [movements, setMovements] = useState()
    const [movementsOrganised, setMovementsOrganised] = useState()
    const [paymentTotalPaid, setPaymentTotalPaid] = useState()

    function handleClose() {
        setShow(false);
    }


    useEffect(() => {
        if (show) {
            getMovementsFromPaymentId()
        } else {
            setMovements()
            setMovementsOrganised()
            setPaymentTotalPaid()
        }
    }, [show])

    function getMovementsFromPaymentId() {
        fetch(`${url_server}private/movements/${accountDetails.photographer_id}?payment_id=${movement.payment_id}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'x-access-token': accountDetails.token
            },
        })
            .then((response) => response.json())

            .then((data) => {
                // response = data.response
                // // console.log(data.response)
                if (data.error) {
                    alert("Something went wrong")
                } else {
                    setMovements(data.response)
                }


            })
            .catch((err) => {
                // console.log(err)
            });
    }

    useEffect(() => {

        if (movements != null) {
            var latest_album_id = null
            var array_albums = []
            var payment_total_paid = 0
            movements.map((obj, index) => {
                if (obj.album_id != latest_album_id) {
                    latest_album_id = obj.album_id

                    array_albums.push({
                        location_name: obj.location_name,
                        location_city: obj.location_city,
                        location_state: obj.location_state,
                        album_id: obj.album_id,
                        discount_percentage: obj.discount_percentage,
                        album_datetime_start: obj.album_datetime_start,
                        album_datetime_end: obj.album_datetime_end,
                        movements: [{
                            movement_id: obj.movement_id,
                            movement_amount: obj.movement_amount,
                            product_id: obj.product_id,
                            price: obj.price,
                            product_type_id: obj.product_type_id,
                            media_url: obj.media_url
                        }]
                    })

                    array_albums[array_albums.length - 1].total_price_products = obj.price
                    array_albums[array_albums.length - 1].total_price_products_discounted = (obj.price - (obj.price * obj.discount_percentage))
                    array_albums[array_albums.length - 1].total_album_discount = (obj.price * obj.discount_percentage)

                    payment_total_paid += (obj.price - (obj.price * obj.discount_percentage))



                } else {
                    array_albums[array_albums.length - 1].movements.push({
                        movement_id: obj.movement_id,
                        movement_amount: obj.movement_amount,
                        product_id: obj.product_id,
                        price: obj.price,
                        product_type_id: obj.product_type_id,
                        media_url: obj.media_url
                    })

                    array_albums[array_albums.length - 1].total_price_products += obj.price
                    array_albums[array_albums.length - 1].total_price_products_discounted += (obj.price - (obj.price * obj.discount_percentage))
                    array_albums[array_albums.length - 1].total_album_discount += (obj.price * obj.discount_percentage)

                    payment_total_paid += (obj.price - (obj.price * obj.discount_percentage))

                }

            })
            setPaymentTotalPaid(payment_total_paid)
            setMovementsOrganised(array_albums)

        }


    }, [movements])

    // console.log(movements)

    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Sale details <span className="text-thin text-grey">{movement != null ? `${Intl.DateTimeFormat('en-au', { timeZone: "Australia/Brisbane", year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" }).format(new Date(movement.movement_datetime))} AEST` : ""} </span></Modal.Title>
                </Modal.Header>
                <Modal.Body>



                    {/* <div className="mb-3"> 
                    <div className='d-flex justify-content-between mb-2'>
                        
                        <p className='me-3 mb-0'><b>Album: </b>Snapper rocks - QLD</p>

                    
                    </div>
                </div> */}

                    {movements == null || movementsOrganised == null ? (
                        <div className="text-center">
                            <Spinner animation="border" role="status" className="mt-5 ">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                        <div>
                            {movementsOrganised.map((album, index) => {
                                return (

                                    <div key={index}>
                                        {/* <Row className="mb-4"> */}


                                        <div >
                                            <div className='d-flex mb-2'>
                                                <p className='me-3 mb-0 p_large'><b>Album: </b>{album.location_name} - {album.location_state}</p>
                                                <p className='me-3 mb-0 text-thin'><b></b>{new Date(album.album_datetime_start).toLocaleDateString()} {new Date(album.album_datetime_start).toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })}</p>

                                            </div>
                                            {album.movements.map((sale, i) => {

                                                return (
                                                    <ProductSales key={i} item={sale} />

                                                )
                                            })}



                                        </div>
                                        <div className="d-flex justify-content-end align-items-end mb-3">
                                            <div className="text-end d-flex">
                                                {album.discount_percentage != null ? (
                                                    <div className="d-flex">
                                                        <p className='me-3 mb-0 p_small mt-0'>Album subtotal: <span className="text-thin"> ${(parseFloat(album.total_price_products) / 100).toFixed(2)}</span></p>
                                                        <p className='me-3 mb-0 p_small mt-0'>Discount applied ({parseInt(parseFloat(album.discount_percentage) * 100) + "%"}): <span className="text-thin"> ${(parseFloat(album.total_album_discount) / 100).toFixed(2)}</span></p>
                                                    </div>

                                                ) : ""}
                                                <p className='me-3 mb-0 p_small mt-0'>Album total: <span className="text-thin">${(parseFloat(album.total_price_products_discounted) / 100).toFixed(2)}</span></p>
                                                {/* <p className='me-3 mb-0'><b>Amount Received:</b> price</p> */}

                                            </div>
                                        </div>
                                        {/* </Row> */}
                                    </div>
                                )
                            })}
                            <hr />
                            <div className="text-end">
                                <p className='me-3 mb-0'><b>Total:</b> ${(parseFloat(paymentTotalPaid / 100).toFixed(2))}</p>
                                <p className='me-3 mb-0'><b>Adm fee: </b>{parseInt(parseFloat(movements[0].movement_paid_fee) * 100)}%</p>
                                <p className='me-3 mb-0'><b>Total received: </b>${(parseFloat(movement.total_amount / 100).toFixed(2))}</p>

                            </div>
                        </div>


                    )}



                    {/* {movements == null ? (
                        // Loading
                        <div className="text-center">
                            <Spinner animation="border" role="status" className="mt-5 ">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                        <>
                        {

                        movements.map((obj, index) =>{


                            return(
                                <ProductSales key={index} item={obj} />

                            )

                        })}

                        </>
                    )} */}


                    {/* </div> */}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-end">
                    <Button className='btn_outlined_blue btn_register' onClick={handleClose}>Close</Button>
                    {/* <Button className='btn_primary_blue btn_register '  onClick={handleAccept}>Apply</Button> */}

                </Modal.Footer>
            </Modal>
        </>
    );
}

function ModalLandscapePhoto({ show, setShow, onAccept, userInfo }) {
    const [data, setData] = useState()
    const [image, setImage] = useState()
    const ic_delete_grey = require('../assets/ic_delete_grey_dark.png')
    const [accountDetails, setAccountDetaills] = useState(SessionDetails())


    function handleClose() {
        setShow(false);
    }

    function handleAccept() {
        onFileUpload(image.file)

    }


    function deleteProduct(e) {
        e.preventDefault();

        setImage()
    }

    useEffect(() => {
        if (show) {
            // console.log(userInfo)
            setImage()
        }
    }, [show])

    // upload profile photo
    async function onFileUpload(file) {

        // Create an object of formData
        const formData = new FormData();
        var file_extension = file.name.split('.');
        file_extension = file_extension[file_extension.length - 1];
        var image = { "extension": file_extension, "path": `photographers-images-${userInfo.photographer_id}` }

        //      save product image compressed
        if (file_extension.toLowerCase() == "jpg" || file_extension.toLowerCase() == "jpeg" || file_extension.toLowerCase() == "png") {
            new Compressor(file, {
                quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                success: async (compressedResult) => {

                    // SAVE COMPRESSED NO WATERMARK

                    const signed_url_response = await getSignedURL(image)
                    if (signed_url_response != null) {

                        var uploaded = await uploadFile(signed_url_response.signedUrl, compressedResult, file)
                        if (uploaded) {
                            // await saveURL(productId, signed_url_response.fileUrl)
                            onAccept(signed_url_response.fileUrl)

                        }
                    }

                },
                error(err) {
                    // console.log(err.message);
                },
            });


        }


    };

    async function getSignedURL(data) {
        var response;

        await fetch(url_server + "private/signedurl", {
            method: 'POST',
            body: JSON.stringify(
                // Add parameters here
                data
            ),
            mimeType: "multipart/form-data",
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                "x-access-token": accountDetails.token,
            },
        })
            .then((response) => response.json())

            .then((data) => {
                // GO TO SUCCESS PAGE
                // signed_url = data.response.signedUrl;
                // file_url = data.response.file_url;
                response = data.response

            })
            .catch((err) => {
                // console.log(err)
                alert("Something went wrong. Please check your connection and try again.");
            });

        return response;

    }

    async function uploadFile(signed_url, compressedFile, file) {


        var uploaded = false;
        await axios.put(signed_url, compressedFile, {
            timeout: 0,
            mimeType: "multipart/form-data",
            headers: {
                "Content-Type": file.type,
                "x-amz-acl": "public-read",
            },

            onUploadProgress: (progressEvent) => {
                if (progressEvent.bytes) {
                    var progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)

                    if (progress == 100) {
                        uploaded = true;
                        // console.log("uploaded")
                    }

                }
            },
        }).then((response) => {
            // // console.log("also uploaded")

        })
            .catch((error) => {
                // Error
                if (error.response) {

                    // console.log("Error")
                } else if (error.request) {
                    // console.log("Error")
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                    // console.log("Error")
                }
            });

        return uploaded;
    }


    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}

            >
                <Modal.Header closeButton>
                    <Modal.Title>Landscape photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 d-flex justify-content-center">
                        {/* <div className="card_img_upload">
                            {image != null ? (
                                <>
                                
                                <Card.Img className=' mt-3' variant="top" src={image.preview} />
                                <img src={ic_delete_grey} alt="delete" className=" ic_delete_image" onClick={(e) => deleteProduct(e)} />
                                </>
                            ) : ("")}
                            

                          
                        </div> */}

                        {image != null ? (
                            <>

                                <div>
                                    <img src={image.preview} alt="profile" className="w-100 default_profile_img_select" />
                                </div>
                            </>
                        ) : ("")}



                    </div>
                    <DragDropFileSingle image={image} setImage={setImage} />


                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button className='btn_outlined_blue btn_register' onClick={handleClose}>Close</Button>
                    <Button className='btn_primary_blue btn_register ' onClick={handleAccept}>Apply</Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

function ModalProfilePhoto({ show, setShow, onAccept, userInfo }) {
    const [data, setData] = useState()
    const [image, setImage] = useState()
    const ic_delete_grey = require('../assets/ic_delete_grey_dark.png')
    const [accountDetails, setAccountDetaills] = useState(SessionDetails())


    function handleClose() {
        setShow(false);
    }

    function handleAccept() {
        if (image != null) {
            onFileUpload(image.file)
        }


    }


    function deleteProduct(e) {
        e.preventDefault();

        setImage()
    }

    useEffect(() => {
        if (show) {
            // console.log(userInfo)
            setImage()
        }
    }, [show])

    // upload profile photo
    async function onFileUpload(file) {

        // Create an object of formData
        const formData = new FormData();
        var file_extension = file.name.split('.');
        file_extension = file_extension[file_extension.length - 1];
        var image = { "extension": file_extension, "path": `photographers-images-${userInfo.photographer_id}` }

        //      save product image compressed
        if (file_extension.toLowerCase() == "jpg" || file_extension.toLowerCase() == "jpeg" || file_extension.toLowerCase() == "png") {
            new Compressor(file, {
                quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                success: async (compressedResult) => {

                    // SAVE COMPRESSED NO WATERMARK

                    const signed_url_response = await getSignedURL(image)
                    if (signed_url_response != null) {

                        var uploaded = await uploadFile(signed_url_response.signedUrl, compressedResult, file)
                        if (uploaded) {
                            // await saveURL(productId, signed_url_response.fileUrl)
                            onAccept(signed_url_response.fileUrl)

                        }
                    }

                },
                error(err) {
                    // console.log(err.message);
                },
            });


        }


    };

    async function getSignedURL(data) {
        var response;

        await fetch(url_server + "private/signedurl", {
            method: 'POST',
            body: JSON.stringify(
                // Add parameters here
                data
            ),
            mimeType: "multipart/form-data",
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                "x-access-token": accountDetails.token,
            },
        })
            .then((response) => response.json())

            .then((data) => {
                // GO TO SUCCESS PAGE
                // signed_url = data.response.signedUrl;
                // file_url = data.response.file_url;
                response = data.response

            })
            .catch((err) => {
                // console.log(err)
                alert("Something went wrong. Please check your connection and try again.");
            });

        return response;

    }

    async function uploadFile(signed_url, compressedFile, file) {


        var uploaded = false;
        await axios.put(signed_url, compressedFile, {
            timeout: 0,
            mimeType: "multipart/form-data",
            headers: {
                "Content-Type": file.type,
                "x-amz-acl": "public-read",
            },

            onUploadProgress: (progressEvent) => {
                if (progressEvent.bytes) {
                    var progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)

                    if (progress == 100) {
                        uploaded = true;
                        // console.log("uploaded")
                    }

                }
            },
        }).then((response) => {
            // // console.log("also uploaded")

        })
            .catch((error) => {
                // Error
                if (error.response) {

                    // console.log("Error")
                } else if (error.request) {
                    // console.log("Error")
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                    // console.log("Error")
                }
            });

        return uploaded;
    }


    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}

            >
                <Modal.Header closeButton>
                    <Modal.Title>Profile photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 d-flex justify-content-center">
                        {/* <div className="card_img_upload">
                            {image != null ? (
                                <>
                                
                                <Card.Img className=' mt-3' variant="top" src={image.preview} />
                                <img src={ic_delete_grey} alt="delete" className=" ic_delete_image" onClick={(e) => deleteProduct(e)} />
                                </>
                            ) : ("")}
                            

                          
                        </div> */}

                        {image != null ? (
                            <>

                                <div className="round_profile_picture">
                                    <img src={image.preview} alt="profile" className="w-100 default_profile_img_select" />

                                </div>
                            </>
                        ) : ("")}



                    </div>
                    <DragDropFileSingle image={image} setImage={setImage} />


                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button className='btn_outlined_blue btn_register' onClick={handleClose}>Close</Button>
                    <Button className='btn_primary_blue btn_register ' onClick={handleAccept}>Apply</Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

function Error({ content }) {
    return (
        <Form.Text className="text-danger">
            {content}
        </Form.Text>
    );
}

function ModalProfileDetails({ show, setShow, onAccept, userInfo }) {
    const [data, setData] = useState()
    const [btandNameError, setBrandNameError] = useState(<BrandNameError />)
    const [tags, setTags] = React.useState([]);
    const [categoriesError, setCategoriesError] = useState(<Error />)
    const [photosTypeError, setPhotosTypeError] = useState(<Error />)
    const [photographerTypeShots, setPhotographerTypeShots] = useState([]);
    const [photographerCateogoryShot, setPhotographerCateogoryShot] = useState([]);

    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
    const [suggestions, setSuggestions] = useState([])

    useEffect(() => {
        if (show) {
            getSuggestions()
            setTags(userInfo != null && Object.keys(userInfo).includes("photographer_main_spots") ? JSON.parse(userInfo.photographer_main_spots) : [])

        }
    }, [show])

    useEffect(() => {
        // setTags(photographerInfo != null && Object.keys(photographerInfo).includes("photographer_main_spots") ? JSON.parse(photographerInfo.photographer_main_spots) : [])
        setPhotographerTypeShots(userInfo != null && userInfo.photographer_products_type != null ? JSON.parse(userInfo.photographer_products_type) : [])
        setPhotographerCateogoryShot(userInfo != null && userInfo.photographer_products_category != null ? JSON.parse(userInfo.photographer_products_category) : [])
    }, [userInfo])



    useEffect(() => {
        setData((oldSearch) => ({ ...oldSearch, "photographer_main_spots": JSON.stringify(tags) }))
    }, [tags])

    function getSuggestions() {
        fetch(url_server + "public/locations/suggestions", {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                // 'x-access-token': session_details.token
            },
        })
            .then((response) => response.json())

            .then((data) => {
                // response = data.response
                // console.log(data.response)
                const sug = []
                data.response.map((obj, index) => {
                    sug.push(obj.location_suggestions)
                })

                setSuggestions(sug)

            })
            .catch((err) => {
                // console.log(err)
            });
    }


    function handleClose() {
        setShow(false);
        setData()
        setTags([])
    }



    function handleAccept() {
        if (data.photographer_business_name != null && data.photographer_business_name == "") {
            setBrandNameError(<BrandNameError content={"Profile name / Photography brand cannot be empty"} />)

        } else {
            setShow(false)
            setBrandNameError(<BrandNameError />)
            onAccept(data)
        }

    }

    const array_states = [
        {
            val: 'QLD',
            text: "Queensland",

        },
        {
            val: "ACT",
            text: "Australian Capital Territory",

        },
        {
            val: "NSW",
            text: "New South Wales",

        },
        {
            val: "SA",
            text: "South Australia",

        },
        {
            val: "NT",
            text: "Northen Territory",

        },
        {
            val: "VIC",
            text: "Victoria",

        },
        {
            val: "TAS",
            text: "Tasmania",

        },
        {
            val: "WA",
            text: "Western Australia",

        },

    ]


    const [inputValue, setInputValue] = useState("");

    const handleTagChange = (newTags) => {
        setTags(newTags);
    };

    const handleInputChange = (event) => {

        if (event.key === "ArrowUp") {
            event.preventDefault();
            setSelectedSuggestionIndex(
                Math.max(selectedSuggestionIndex - 1, 0)
            );
        } else if (event.key === "ArrowDown") {
            event.preventDefault();
            setSelectedSuggestionIndex(
                Math.min(selectedSuggestionIndex + 1, filteredSuggestions.length - 1)
            );
        } else if (event.key === "Enter" && selectedSuggestionIndex !== -1) {
            event.preventDefault();
            handleSuggestionClick(filteredSuggestions[selectedSuggestionIndex]);
            // event.target.value = ""
        } else {
            setInputValue(event.target.value);

        }

        // setInputValue(event.target.value);

    };

    const handleSuggestionClick = (suggestion) => {
        // Add the suggestion to the tags list
        const rtiinput = document.getElementsByClassName('rti--input')[0]
        rtiinput.value = ""
        setTags([...tags, suggestion]);

        // Clear the input value
        setInputValue("");
    };

    const filteredSuggestions = suggestions.length > 0 ? suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(inputValue.toLowerCase())

    ) : [];

    const handlePhotosCB = (event) => {
        if (event.target.checked) {
            photographerTypeShots.push(event.target.value)
        } else {
            const index = photographerTypeShots.indexOf("Photos");

            photographerTypeShots.splice(index, 1);
        }
        setData((oldSearch) => ({ ...oldSearch, "photographer_products_type": JSON.stringify(photographerTypeShots) }))
    }

    const handleVideosCB = (event) => {
        if (event.target.checked) {
            photographerTypeShots.push(event.target.value)
        } else {
            const index = photographerTypeShots.indexOf("Videos");

            photographerTypeShots.splice(index, 1);
        }
        setData((oldSearch) => ({ ...oldSearch, "photographer_products_type": JSON.stringify(photographerTypeShots) }))
    }

    const handleDroneCB = (event) => {
        if (event.target.checked) {
            photographerCateogoryShot.push(event.target.value)
        } else {
            const index = photographerCateogoryShot.indexOf("Drone");

            photographerCateogoryShot.splice(index, 1);
        }
        setData((oldSearch) => ({ ...oldSearch, "photographer_products_category": JSON.stringify(photographerCateogoryShot) }))
    }

    const handleWCCB = (event) => {
        if (event.target.checked) {
            photographerCateogoryShot.push(event.target.value)
        } else {
            const index = photographerCateogoryShot.indexOf("Camera Water");

            photographerCateogoryShot.splice(index, 1);
        }
        setData((oldSearch) => ({ ...oldSearch, "photographer_products_category": JSON.stringify(photographerCateogoryShot) }))
    }


    const handleLandCB = (event) => {
        if (event.target.checked) {
            photographerCateogoryShot.push(event.target.value)
        } else {
            const index = photographerCateogoryShot.indexOf("Land");

            photographerCateogoryShot.splice(index, 1);
        }
        setData((oldSearch) => ({ ...oldSearch, "photographer_products_category": JSON.stringify(photographerCateogoryShot) }))
    }


    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="p-0"
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Your details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" >
                            <Form.Label className="fw-bold ">Profile name / Photography brand</Form.Label>
                            <Form.Control type="text" placeholder="Type here" defaultValue={userInfo.photographer_business_name} onChange={(e) => setData((oldSearch) => ({ ...oldSearch, "photographer_business_name": e.target.value }))} />
                            {/* <div id="emailHelp" className="form-text">Optional. This field is shown on your profile. If blank, we will use your name.</div> */}
                            {btandNameError}
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label className="fw-bold">Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Type here"
                                rows={3}
                                required
                                defaultValue={userInfo.photographer_description}
                                onChange={(e) => setData((oldSearch) => ({ ...oldSearch, "photographer_description": e.target.value }))}
                            />
                        </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="formBasicSocialMedia">
                        <Form.Label className="fw-bold ">Instagram </Form.Label>
                    
                        <InputGroup >
                    
                            <InputGroup.Text className=' ' id="inputGroupPrepend">@</InputGroup.Text>
                            <Form.Control defaultValue={userInfo.photographer_instagram} type="text" placeholder="Type Here" onChange={(e) => setData((oldSearch) => ({...oldSearch, "photographer_instagram": e.target.value}))} />

                        </InputGroup>
                        <div id="emailHelp" className="form-text">This will be shown in your albums to promote your social media on the platform</div>


                    </Form.Group> */}

                        <Row>
                            <Form.Label className="fw-bold">Where are you based?</Form.Label>

                            <Col md={6}>

                                <Form.Group className="mb-3" >
                                    {/* <Form.Label className="fw-bold">Where are you based?</Form.Label> */}
                                    <Form.Select
                                        aria-label="Default select example"
                                        onChange={(e) => setData((oldSearch) => ({ ...oldSearch, "photographer_public_state": e.target.value }))}
                                        placeholder="State"
                                        defaultValue={userInfo.photographer_public_state}
                                    >
                                        <option value="">Select state</option>
                                        {array_states.map((obj, index) => {
                                            return (
                                                <option key={index} value={obj.val}  >{obj.text}</option>
                                            )
                                        })}


                                    </Form.Select>

                                </Form.Group>


                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" >
                                    {/* <Form.Label className="fw-bold ">City</Form.Label> */}
                                    <Form.Control type="text" placeholder="City" defaultValue={userInfo.photographer_public_city} onChange={(e) => setData((oldSearch) => ({ ...oldSearch, "photographer_public_city": e.target.value }))} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div>
                            <Form.Label className="fw-bold ">Surf spots you usually shoot</Form.Label>
                            <p className="text-thin p_xsmall m-0">Please, press "," or ";" to insert tags</p>

                            <TagsInput
                                value={tags}
                                onChange={setTags}
                                name="locations"
                                placeHolder="Enter locations"
                                className="w-100"
                                separators={[","]}
                                inputValue={inputValue}
                                onKeyUp={handleInputChange}

                            />
                            {inputValue && filteredSuggestions.length > 0 && (
                                <ul className="ul_list_autocomplete_locations">
                                    {filteredSuggestions.map((suggestion, index) => (
                                        <li className={selectedSuggestionIndex === index ? "li_list_autocomplete_locations selected_suggestion" : "li_list_autocomplete_locations"} key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                            {suggestion}
                                        </li>
                                    ))}
                                </ul>
                            )}

                        </div>

                        <Row>
                            <Form.Group className="mb-3 mt-3">

                                <Form.Label className="fw-bold ">Kind of media you shoot</Form.Label>
                                <div className='radio_inline_blue'>
                                    <Form.Check
                                        inline
                                        label="Photos"
                                        name="group1"
                                        type="checkbox"
                                        value="Photos"
                                        onChange={handlePhotosCB}
                                        defaultChecked={
                                            JSON.parse(userInfo.photographer_products_type).find(function (str) {
                                                return str == "Photos"
                                            })
                                        }
                                    />

                                    <Form.Check
                                        inline
                                        label="Videos"
                                        name="group1"
                                        type="checkbox"
                                        value="Videos"
                                        onChange={handleVideosCB}
                                        defaultChecked={
                                            JSON.parse(userInfo.photographer_products_type).find(function (str) {
                                                return str == "Videos"
                                            })
                                        }
                                    />

                                </div>
                                {photosTypeError}
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Label className="fw-bold ">From which position do you shoot?</Form.Label>
                                <div className='radio_inline_blue'>
                                    <Form.Check

                                        inline
                                        label="Drone"
                                        name="group2"
                                        type="checkbox"
                                        value="Drone"
                                        onChange={handleDroneCB}
                                        defaultChecked={
                                            JSON.parse(userInfo.photographer_products_category).find(function (str) {

                                                return str == "Drone"
                                            })
                                        }

                                    />
                                    <Form.Check

                                        inline
                                        label="In Water"
                                        name="group2"
                                        type="checkbox"
                                        value="In Water"
                                        onChange={handleWCCB}
                                        defaultChecked={
                                            JSON.parse(userInfo.photographer_products_category).find(function (str) {

                                                return str == "In Water"
                                            })
                                        }
                                    />
                                    <Form.Check

                                        inline
                                        label="Land"
                                        name="group2"
                                        type="checkbox"
                                        value="Land"
                                        onChange={handleLandCB}
                                        defaultChecked={
                                            JSON.parse(userInfo.photographer_products_category).find(function (str) {
                                                return str == "Land"
                                            })
                                        }
                                    />

                                </div>
                                {categoriesError}
                            </Form.Group>
                        </Row>

                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button className='btn_outlined_blue btn_register' onClick={handleClose}>Close</Button>
                    <Button className='btn_primary_blue btn_register ' onClick={handleAccept}>Apply</Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

function ModalDisocuntsEdit({ show, setShow, onAccept, album, discounts }) {
    const [discountsOriginal, setDiscountsOriginal] = useState([{ album_id: album.album_id, discount_quantity: "", discount_percentage: "" }])


    function handleClose() {
        setShow(false);
    }

    function handleAccept() {
        // console.log(discountsOriginal)
        if (validateDiscounts()) {
            setShow(false)
            onAccept(discountsOriginal)

        }
    }

    function validateDiscounts() {
        if (discountsOriginal.length > 0) {

            for (var i = 0; i < discountsOriginal.length; i++) {
                if (discountsOriginal[i].discount_quantity == "" || discountsOriginal[i].discount_percentage == "") {
                    alert("Please set all discounts")
                    return false;
                }
            }
        }
        return true;
    }

    useEffect(() => {

        if (show) {
            // console.log("newArr")

            if (discounts.length > 0) {
                var newArr = []
                // for(var i = 0; i < discounts.length; i++){
                //     newArr.push({album_id: discounts[i].album_id, discount_quantity: discounts[i].discount_quantity, discount_percentage: discounts[i].discount_percentage})

                // }

                discounts.map((obj, index) => {
                    newArr.push({ album_id: album.album_id, discount_quantity: obj.discount_quantity, discount_percentage: obj.discount_percentage })
                })
                setDiscountsOriginal(newArr)
            } else {
                setDiscountsOriginal([{ album_id: album.album_id, discount_quantity: "", discount_percentage: "" }])
            }

        } else {
            setDiscountsOriginal([{ album_id: album.album_id, discount_quantity: "", discount_percentage: "" }])
        }
    }, [show])

    useEffect(() => {
        // var obj_discounts = []
        // discountsOriginal.map((obj, index) =>{
        //     if(obj.discount_quantity != "" || (obj.discount_percentage != "" && !isNaN(obj.discount_percentage))){
        //         obj_discounts.push(obj)
        //     }
        // })

    }, [discountsOriginal])

    function addDiscount() {
        setDiscountsOriginal(oldArray => [...oldArray, { album_id: album.album_id, discount_quantity: "", discount_percentage: "" }])

    }


    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Discounts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <h5 className="text-blue m-0"><b>Add discounts</b></h5>
                            <Button className='btn_outlined_blue btn_register m-0' onClick={addDiscount}>Add discount</Button>

                        </div>
                        <div id="div_discounts" className="mt-3">
                            {

                                discountsOriginal.map((obj, index) => {
                                    return (
                                        <SetDiscount key={`${obj.property}-${index}`} index={index} discount={obj} discounts={discountsOriginal} setDiscounts={setDiscountsOriginal} />
                                    )
                                })
                            }

                        </div>

                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button className='btn_outlined_blue btn_register' onClick={handleClose}>Close</Button>
                    <Button className='btn_primary_blue btn_register ' onClick={handleAccept}>Apply</Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

function ModalPriceVideos({ show, setShow, onAccept, album, photographerInfo }) {
    const [price, setPrice] = useState()


    function handleClose() {
        setShow(false);
    }

    useEffect(() => {
        if (show) {
            setPrice()
        }
    }, [show])


    function handleAccept() {

        if (price != null && price > 0) {
            setShow(false)

            onAccept(parseInt(parseFloat(price) * 100))
        } else {
            alert("Price needs to be a number and bigger than 0")
        }
        // if(newCategory != null){
        //     onAccept(newCategory)

        // }
    }


    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Set general video price</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Videos price </Form.Label>
                            <Form.Control type="number" className='form-control-md ' placeholder='Type here' onChange={(e) => setPrice(e.target.value)} />
                            {price != "" && price != null ? (
                                <p className="text-thin mt-1"><b>You will get </b>${(((parseFloat(price)) * photographerInfo.photographer_percentage_receive)).toFixed(2)}</p>

                            ) : (
                                <></>
                            )}
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button className='btn_outlined_blue btn_register' onClick={handleClose}>Close</Button>
                    <Button className='btn_primary_blue btn_register ' onClick={handleAccept}>Apply</Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

function ModalPricePhotos({ show, setShow, onAccept, album, photographerInfo }) {
    const [price, setPrice] = useState()

    useEffect(() => {
        if (show) {
            setPrice()
        }
    }, [show])

    function handleClose() {
        setShow(false);
    }

    function handleAccept() {

        if (price != null && price > 0) {
            setShow(false)

            onAccept(parseInt(parseFloat(price) * 100))
        } else {
            alert("Price needs to be a number and bigger than 0")
        }

    }


    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Set general photo price</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Photos price </Form.Label>
                            <Form.Control type="number" className='form-control-md ' placeholder='Type here' onChange={(e) => setPrice(e.target.value)} />
                            {price != "" && price != null ? (
                                <p className="text-thin mt-1"><b>You will get </b>${(((parseFloat(price)) * photographerInfo.photographer_percentage_receive)).toFixed(2)}</p>

                            ) : (
                                <></>
                            )}
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button className='btn_outlined_blue btn_register' onClick={handleClose}>Close</Button>
                    <Button className='btn_primary_blue btn_register ' onClick={handleAccept}>Apply</Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

function ModalCategoryEdit({ show, setShow, onAccept, album }) {
    const [newCategory, setNewCategory] = useState()


    function handleClose() {
        setShow(false);
    }

    function handleAccept() {

        // console.log(newCategory)
        setShow(false)

        if (newCategory != null) {
            onAccept(newCategory)

        }
    }


    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Set category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Category <span className="text_thin">(Select one)</span></Form.Label>

                            <div className='radio_inline_blue d-flex justify-content-between'>
                                <Form.Check
                                    required
                                    inline
                                    label="Land"
                                    name="group1"
                                    type="radio"
                                    value="Land"
                                    defaultChecked={album.album_categories == "Land" ? true : false}

                                    onClick={(e) => setNewCategory((oldSearch) => ({ ...oldSearch, "album_categories": e.target.value }))}
                                />
                                <Form.Check
                                    required
                                    inline
                                    label="Drone"
                                    name="group1"
                                    type="radio"
                                    value="Drone"
                                    defaultChecked={album.album_categories == "Drone" ? true : false}


                                    onClick={(e) => setNewCategory((oldSearch) => ({ ...oldSearch, "album_categories": e.target.value }))}
                                />
                                <Form.Check
                                    required
                                    inline
                                    label="In water"
                                    name="group1"
                                    type="radio"
                                    value="In water"
                                    defaultChecked={album.album_categories == "In water" ? true : false}


                                    onClick={(e) => setNewCategory((oldSearch) => ({ ...oldSearch, "album_categories": e.target.value }))}
                                />

                            </div>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button className='btn_outlined_blue btn_register' onClick={handleClose}>Close</Button>
                    <Button className='btn_primary_blue btn_register ' onClick={handleAccept}>Apply</Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

function ModalTimeEdit({ show, setShow, onAccept, album }) {
    // const [show, setShow] = useState(false);
    const [inputDate, setInputDate] = useState("");
    const [inputStartTime, setInputStartTime] = useState("");
    const [inputEndTime, setInputEndTime] = useState("");
    const [newDate, setNewDate] = useState('')
    // const [newEndtDate, setNewEndtDate] = useState('')

    useEffect(() => {
        if (show) {
            // // console.log( formatDateToYYYYMMDD(new Date(album.album_datetime_start).toLocaleDateString()))
            setInputDate(formatDateToYYYYMMDD(new Date(album.album_datetime_start).toLocaleDateString()))
            setInputStartTime(new Date(album.album_datetime_start).toLocaleTimeString())
            setInputEndTime(new Date(album.album_datetime_end).toLocaleTimeString())
        }

    }, [show])

    function handleClose() {
        setShow(false);
    }

    function handleAccept() {
        // setShow(false)
        if (validateData()) {
            setShow(false)
            var obj = newDate
            obj.album_datetime_start = new Date(newDate.album_datetime_start)
            obj.album_datetime_end = new Date(newDate.album_datetime_end)
            onAccept(obj)

        }

    }

    function formatDateToYYYYMMDD(dateString) {
        return dateString.split("/").reverse().join("-");
    }

    function validateData() {
        var is_valid = true
        if (inputStartTime == "" || inputEndTime == "" || inputDate == "" || isNaN(Date.parse(newDate.album_datetime_start)) && isNaN(Date.parse(newDate.album_datetime_end))) {
            is_valid = false
            alert("Please enter all details.")
        } else if (Date.parse(newDate.album_datetime_start) >= Date.parse(newDate.album_datetime_end)) {
            is_valid = false
            alert("Start date cannot be after end date.")


        } else if (new Date(newDate.album_datetime_start) > new Date() || new Date(newDate.album_datetime_end) > new Date()) {
            is_valid = false
            alert("Dates cannot be in the future")
        } else {
            return is_valid
        }


    }

    function onChangeDate(e) {
        setInputDate(e.target.value)
    }

    function onChangeStartDateTime(e) {

        setInputStartTime(e.target.value)
    }

    function onChangeEndDateTime(e) {

        setInputEndTime(e.target.value)
    }

    useEffect(() => {

        if (inputDate != "" || inputStartTime != "" || inputEndTime != "") {
            // console.log("date modifiying")
            setNewDate((oldSearch) => ({ ...oldSearch, "album_datetime_start": inputDate + " " + inputStartTime }))
            setNewDate((oldSearch) => ({ ...oldSearch, "album_datetime_end": inputDate + " " + inputEndTime }))
        }



    }, [inputDate, inputStartTime, inputEndTime])


    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Set date and time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Date</Form.Label>

                            <Form.Control type="date" className='form-control-md search_personalized_date' placeholder='Date'
                                // onChange={(e) => setInputDate(e.target.value)}
                                onChange={onChangeDate}


                                // onFocus={(e) => (e.target.type = "date")}
                                // onBlur={(e) => (e.target.type = "text")} 
                                defaultValue={album.album_datetime_start != null ? formatDateToYYYYMMDD(new Date(album.album_datetime_start).toLocaleDateString()) : ""}
                            />


                        </Form.Group>

                        <div className="d-flex w-100 mb-3">
                            <Form.Group className="me-2 w-100">
                                <Form.Label className="fw-bold">Start time</Form.Label>
                                <Form.Control
                                    type="time"
                                    className='form-control-md w-100'
                                    placeholder='Type Here'
                                    onChange={onChangeStartDateTime}
                                    defaultValue={new Date(album.album_datetime_start).toLocaleTimeString()}


                                />

                            </Form.Group>
                            <Form.Group className="ms-2 w-100">
                                <Form.Label className="fw-bold">End time</Form.Label>
                                <Form.Control
                                    type="time"
                                    className='form-control-md w-100'
                                    placeholder='Type Here'
                                    onChange={onChangeEndDateTime}
                                    defaultValue={new Date(album.album_datetime_end).toLocaleTimeString()}

                                />

                            </Form.Group>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button className='btn_outlined_blue btn_register' onClick={handleClose}>Close</Button>
                    <Button className='btn_primary_blue btn_register ' onClick={handleAccept}>Apply</Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}


function ModalEditLocation({ show, setShow, onAccept, album }) {
    // const [show, setShow] = useState(false);
    const [userDetails, setUserDetails] = useState(SessionDetails())
    const [cities, setCities] = useState()
    const [locationsGlobal, setLocationsGlobal] = useState({})
    const [newLocationId, setNewLocationId] = useState()
    const [spots, setSpots] = useState()

    function handleClose() {
        setShow(false);
    }

    function handleAccept() {
        // setShow(false)
        if (validateData()) {
            onAccept(newLocationId)
            setShow(false)
        }
    }

    function validateData() {

        if (newLocationId == null) {
            setShow(false)
            return false
        }

        if (newLocationId.location_id == "") {
            alert("Location cannot be empty.")
            return false
        }

        return true
    }


    useEffect(() => {
        if (show) {
            getCities(album.location_state)
            getSpots(album.location_city)

        }

    }, [show])

    const array_states = [
        {
            val: 'QLD',
            text: "Queensland",

        },
        {
            val: "ACT",
            text: "Australian Capital Territory",

        },
        {
            val: "NSW",
            text: "New South Wales",

        },
        {
            val: "SA",
            text: "South Australia",

        },
        {
            val: "NT",
            text: "Northen Territory",

        },
        {
            val: "VIC",
            text: "Victoria",

        },
        {
            val: "TAS",
            text: "Tasmania",

        },
        {
            val: "WA",
            text: "Western Australia",

        },

    ]

    function getCities(state) {
        var query = `?location_state=${state}`;

        fetch(url_server + "public/locations/cities" + query, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((response) => response.json())

            .then((data) => {
                // response = data.response

                setCities(data.response)

            })
            .catch((err) => {
                // console.log(err)
            });
    }

    function onChangeSpot(value, name) {
        // console.log(name)
        if (value != null && value != "") {

            setLocationsGlobal((oldSearch) => ({ ...oldSearch, "location_name": name }))

            setNewLocationId((oldSearch) => ({ ...oldSearch, "location_id": value }))

        } else {
            setLocationsGlobal((oldSearch) => ({ ...oldSearch, "location_name": "" }))

        }
    }

    function onChangeState(value) {
        if (value != null && value != "") {
            setLocationsGlobal((oldSearch) => ({ ...oldSearch, "location_state": value }))

            setLocationsGlobal((oldSearch) => ({ ...oldSearch, "location_city": "" }))
            setLocationsGlobal((oldSearch) => ({ ...oldSearch, "location_name": "" }))

            setCities([])
            setSpots([])
            getCities(value)
        } else {
            setLocationsGlobal((oldSearch) => ({ ...oldSearch, "location_state": "" }))
            setLocationsGlobal((oldSearch) => ({ ...oldSearch, "location_name": "" }))
            setLocationsGlobal((oldSearch) => ({ ...oldSearch, "location_city": "" }))

        }
    }

    function onChangeCity(value) {
        if (value != null && value != "") {
            // setSuburbs([])
            setLocationsGlobal((oldSearch) => ({ ...oldSearch, "location_city": value }))

            setLocationsGlobal((oldSearch) => ({ ...oldSearch, "location_name": "" }))

            setSpots([])
            getSpots(value)
        } else {
            setLocationsGlobal((oldSearch) => ({ ...oldSearch, "location_city": "" }))

            setLocationsGlobal((oldSearch) => ({ ...oldSearch, "location_name": "" }))

        }
    }


    function getSpots(suburb) {
        var query = `?location_city=${suburb}`;

        fetch(url_server + "public/locations/spots" + query, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((response) => response.json())

            .then((data) => {


                setSpots(data.response)

            })
            .catch((err) => {
                // console.log(err)
            });
    }

    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Set location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" >
                            <Form.Label className="fw-bold">State</Form.Label>
                            <Form.Select
                                aria-label="Default select example"
                                onChange={(e) => onChangeState(e.target.value)}

                                required
                                // key={"OKAYG_" + (10000 + Math.random() * (1000000 - 10000))}
                                defaultValue={album.location_state}
                            >
                                <option value=""></option>
                                {array_states.map((obj, index) => {
                                    return (
                                        <option key={index} value={obj.val}  >{obj.text}</option>
                                    )
                                })}


                            </Form.Select>

                        </Form.Group>

                        {cities != null ? (
                            <Form.Group className="mb-3" >
                                <Form.Label className="fw-bold">Region</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    onChange={(e) => onChangeCity(e.target.value)}
                                    required
                                    defaultValue={album.location_city}


                                >
                                    <option value="" ></option>

                                    {

                                        cities.map((obj, index) => {
                                            return (
                                                <option key={index} value={obj.location_city}>{obj.location_city}</option>

                                            )
                                        })
                                    }


                                </Form.Select>

                            </Form.Group>

                        ) : ("")}


                        {spots != null ? (
                            <Form.Group className="mb-3" >
                                <Form.Label className="fw-bold">Spot</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    onChange={(e) => onChangeSpot(e.target.value, e.target.options[e.target.selectedIndex].dataset.value)}
                                    required
                                    defaultValue={album.location_id}

                                >
                                    <option value=""></option>
                                    {
                                        spots.map((obj, index) => {
                                            return (
                                                <option key={index} data-value={obj.location_name} value={obj.location_id}>{obj.location_name}</option>

                                            )
                                        })
                                    }


                                </Form.Select>

                            </Form.Group>

                        ) : ("")}

                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button className='btn_outlined_blue btn_register' onClick={handleClose}>Close</Button>
                    <Button className='btn_primary_blue btn_register ' onClick={handleAccept}>Apply</Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

function LeaveSiteModal({ show, setShow, title, content, onAccept }) {
    // const [show, setShow] = useState(false);
    const [userDetails, setUserDetails] = useState(SessionDetails())
    // const [show, setShow] = useState(true)

    function handleClose() {
        setShow(false);
    }

    function handleAccept() {
        setShow(false)
        onAccept()
    }

    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <p className="p_large">{title}</p>
                    <p>{content}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    {/* <div className="d-flex justify-content-between"> */}
                    <Button className='btn_outlined_blue btn_register' onClick={handleClose}>Close</Button>
                    <Button className='btn_danger btn_register ' onClick={handleAccept}>Delete</Button>
                    {/* </div> */}



                </Modal.Footer>
            </Modal>
        </>
    );
}


function DeleteModal({ show, setShow, title, content, onAccept, item }) {
    // const [show, setShow] = useState(false);
    const [userDetails, setUserDetails] = useState(SessionDetails())
    // const [show, setShow] = useState(true)

    function handleClose() {
        setShow(false);
    }

    function handleAccept() {
        setShow(false)
        onAccept(item)
    }

    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <p className="p_large">{title}</p>
                    <p>{content}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    {/* <div className="d-flex justify-content-between"> */}
                    <Button className='btn_outlined_blue btn_register' onClick={handleClose}>Close</Button>
                    <Button className='btn_danger btn_register ' onClick={handleAccept}>Delete</Button>
                    {/* </div> */}



                </Modal.Footer>
            </Modal>
        </>
    );
}



export {
    DeleteModal,
    ModalEditLocation,
    ModalTimeEdit,
    ModalCategoryEdit,
    ModalDisocuntsEdit,
    ModalPricePhotos,
    ModalPriceVideos,
    LeaveSiteModal,
    ModalProfileDetails,
    ModalProfilePhoto,
    ModalMovementDetails,
    ModalLandscapePhoto,
    ModalContactDetails,
    ModalSocialNetworkInfo,
    ModalConfirmPassword
}